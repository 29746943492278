import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import React, { FC } from 'react';

import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  Copy,
} from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import { NextIcon, PreviousIcon, RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import { PlayerInformationHistoryEventType } from '@/globalTypes';
import { assert } from '@/utils/error';
import usePlayerInformationHistory from './usePlayerInformationHistory';

export const Fragment = graphql`
  fragment SanityPlayerInformationHistoryBlock on SanityPlayerInformationHistoryBlock {
    title {
      ...SanityLocaleString
    }
  }
`;

const PlayerInformationHistoryBlock: FC<{
  block: Queries.SanityPlayerInformationHistoryBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const { playerId } = useParams();

  assert(playerId, 'missing playerId');

  const {
    entries,
    fetching,
    nextPage,
    previousPage,
    refresh,
    orderBy,
    desc,
    setOrderBy,
    defaultFilter,
    updateFilter,
  } = usePlayerInformationHistory(playerId);

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'playerInformationHistory',
    data: entries,
    fetching,
    orderBy,
    desc,
    setOrderBy,
    defaultFilter,
    updateFilter,
    schema: (s) => [
      s.dateTimeValue({
        field: 'timestamp',
        title: 'Time',
        fromFilterField: 'from',
        toFilterField: 'to',
      }),
      s.stringValue({
        field: 'eventId',
        title: 'Event ID',
        getValue({ row }) {
          return <Copy value={row.eventId} />;
        },
      }),
      s.stringValue({
        field: 'service',
        title: 'Service',
      }),
      s.initiatorValue({
        field: 'initiator',
        title: 'Initiator',
      }),
      s.enumValue({
        field: 'eventType',
        title: 'Event',
        e: PlayerInformationHistoryEventType,
        filterField: 'eventType',
        getValue({ row }) {
          return <Copy value={row.eventType} />;
        },
      }),
      s.stringValue({
        field: 'value',
        title: 'Details',
      }),
    ],
  });

  if (!block) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(block.title)}
      showOptionsAtBottom
      options={
        <CardOptions>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
          <GlobalCardOptions />
          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <DataGrid />
      </CardBody>
    </Card>
  );
};

export default PlayerInformationHistoryBlock;
