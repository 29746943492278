import React from 'react';

import { KYC6MatchTypes } from '@/globalTypes';

export const renderTypeOfMatch = (
  typeOfMatch: KYC6MatchTypes[],
): React.ReactElement => {
  const typeClasses = {
    [KYC6MatchTypes.PEP]: 'bg-sky-900 text-white px-1 rounded',
    [KYC6MatchTypes.REL]: 'bg-sky-600 text-white px-1 rounded',
    [KYC6MatchTypes.RRE]: 'bg-cyan-700 text-white px-1 rounded',
    [KYC6MatchTypes.SAN]: 'bg-blue-950 text-white px-1 rounded',
    default: 'bg-gray-500 text-white px-1 py-0.5 rounded',
  };

  return (
    <span className="flex gap-1">
      {typeOfMatch.map((type, i) => {
        return (
          <span key={i} className={typeClasses[type] || typeClasses.default}>
            {type}
          </span>
        );
      })}
    </span>
  );
};
