import React, { FC } from 'react';

import { LimitPeriodDetailList } from '@/bits';
import { LimitBar } from '@/components';
import {
  PlayerCancelDepositLimitForm,
  PlayerDepositLimitsForm,
  PlayerRemoveDepositLimitForm,
} from '@/forms';
import { LimitType, LimitTypeEnum } from '@/globalTypes';
import { Nullable } from '@/types';
import { PlayerDepositLimitsBoxQuery } from './__generated__/component';

type DepositLimitBarsProps = {
  wallet: PlayerDepositLimitsBoxQuery['player']['wallet'] | undefined;
  fetching: boolean;
  playerId: string;
  dailyLimitValue: string;
  weeklyLimitValue: string;
  monthlyLimitValue: string;
  currencyValueFormatter: (value: Nullable<number>) => string;
};

const DepositLimitBars: FC<DepositLimitBarsProps> = ({
  wallet,
  fetching,
  playerId,
  dailyLimitValue,
  weeklyLimitValue,
  monthlyLimitValue,
  currencyValueFormatter,
}) => {
  const depositLimit = wallet?.depositLimit;

  return (
    <div className="grid gap-2 pt-1">
      <LimitBar
        currency={wallet?.currency}
        fetching={fetching}
        limit={depositLimit?.dayLimit}
        value={dailyLimitValue}
        moreDetails={
          <LimitPeriodDetailList
            limit={depositLimit?.dayLimit}
            limitTypeTitle={LimitType.Deposit}
            limitPeriodType={LimitTypeEnum.Day}
            formatValue={currencyValueFormatter}
          />
        }
        changeLimit={
          <PlayerDepositLimitsForm
            playerId={playerId}
            limitPeriodType={LimitTypeEnum.Day}
            currency={wallet?.currency}
            limit={depositLimit?.dayLimit}
          />
        }
        removeLimit={
          <PlayerRemoveDepositLimitForm
            playerId={playerId}
            limitPeriodType={LimitTypeEnum.Day}
          />
        }
        cancelLimit={
          <PlayerCancelDepositLimitForm
            playerId={playerId}
            limitPeriodType={LimitTypeEnum.Week}
          />
        }
      />
      <LimitBar
        currency={wallet?.currency}
        fetching={fetching}
        limit={depositLimit?.weekLimit}
        value={weeklyLimitValue}
        moreDetails={
          <LimitPeriodDetailList
            limit={depositLimit?.weekLimit}
            limitTypeTitle={LimitType.Deposit}
            limitPeriodType={LimitTypeEnum.Week}
            formatValue={currencyValueFormatter}
          />
        }
        changeLimit={
          <PlayerDepositLimitsForm
            playerId={playerId}
            limitPeriodType={LimitTypeEnum.Week}
            currency={wallet?.currency}
            limit={depositLimit?.weekLimit}
          />
        }
        removeLimit={
          <PlayerRemoveDepositLimitForm
            playerId={playerId}
            limitPeriodType={LimitTypeEnum.Week}
          />
        }
        cancelLimit={
          <PlayerCancelDepositLimitForm
            playerId={playerId}
            limitPeriodType={LimitTypeEnum.Week}
          />
        }
      />
      <LimitBar
        currency={wallet?.currency}
        fetching={fetching}
        limit={depositLimit?.monthLimit}
        value={monthlyLimitValue}
        moreDetails={
          <LimitPeriodDetailList
            limit={depositLimit?.monthLimit}
            limitTypeTitle={LimitType.Deposit}
            limitPeriodType={LimitTypeEnum.Month}
            formatValue={currencyValueFormatter}
          />
        }
        changeLimit={
          <PlayerDepositLimitsForm
            playerId={playerId}
            limitPeriodType={LimitTypeEnum.Month}
            currency={wallet?.currency}
            limit={depositLimit?.monthLimit}
          />
        }
        removeLimit={
          <PlayerRemoveDepositLimitForm
            playerId={playerId}
            limitPeriodType={LimitTypeEnum.Month}
          />
        }
        cancelLimit={
          <PlayerCancelDepositLimitForm
            playerId={playerId}
            limitPeriodType={LimitTypeEnum.Month}
          />
        }
      />
    </div>
  );
};

export default DepositLimitBars;
