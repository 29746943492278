import gql from 'graphql-tag';
import { useQuery } from 'urql';
import { useQueryParams } from 'use-query-params';

import { mapVariables } from '@/utils';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  ChallengeDetailQuery,
  ChallengeDetailQueryVariables,
} from './__generated__/useChallengeDetail';
import { queryParams } from './queryParams';

const CHALLENGE_DETAIL_QUERY = gql`
  query ChallengeDetail(
    $challengeId: String!
    $status: [PlayerProgressStatus!]!
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    viewer {
      id
      challenges: challengesV2(challengeId: $challengeId) {
        edges {
          node {
            challengeUUID
            challengeId
            eligibleCriteria
            name
            numberOfPlayers
            challengeActions
            status
            startDate
            endDate
            countries
            brands
            challengeRewards {
              challengeRewardType {
                name
                ... on FreeSpinChallengeReward {
                  __typename
                  name
                  betLevel
                  freeSpinsAmount: amount
                  value
                  currency
                  gameDescriptor {
                    ... on GameDescriptorV2 {
                      __typename
                      json
                    }
                  }
                }
                ... on MoneyDropChallengeReward {
                  __typename
                  name
                  moneyDropAmount: amount
                  currency
                }
                ... on BonusMoneyDropChallengeReward {
                  __typename
                  name
                  bonusMoneyDropAmount: amount
                  currency
                  wageringRequirementFactor
                  maxPayout
                }
                ... on DynamicBonusMoneyDropChallengeReward {
                  __typename
                  name
                  maxAmount
                  currency
                  multiplier
                  wageringRequirementFactor
                  maxPayout
                }
                ... on DynamicFreeSpinChallengeReward {
                  __typename
                  name
                  value
                  currency
                  betLevel
                  maxFreeSpinsAmount
                  multiplier
                  gameDescriptor {
                    ... on GameDescriptorV2 {
                      __typename
                      json
                    }
                  }
                }
                ... on DynamicMoneyDropChallengeReward {
                  __typename
                  name
                  maxAmount
                  currency
                  multiplier
                }
              }
            }
          }
        }
      }
      playersChallenge: playersProgress(
        challengeIdList: [$challengeId]
        status: $status
        first: $first
        last: $last
        after: $after
        before: $before
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            playerUUID
            playerId
            challengeCriteriaCompleted
            challengeCriteriaTotal
            timesCompleted
            timesRestarted
          }
        }
      }
    }
  }
`;

export default function useChallengeDetail(challengeId: string) {
  const [query, setQuery] = useQueryParams(queryParams);

  const [{ data, fetching }, refresh] = useQuery<
    ChallengeDetailQuery,
    ChallengeDetailQueryVariables
  >({
    query: CHALLENGE_DETAIL_QUERY,
    variables: {
      first: 20,
      challengeId,
      status: [],
      ...mapVariables(query),
    },
    requestPolicy: 'cache-and-network',
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makeOrderBy(query, setQuery),
    ...makePagination(query, setQuery, data?.viewer.playersChallenge.pageInfo),
    challenge: data?.viewer.challenges?.edges?.map((e) => e?.node)[0],
    players: data?.viewer.playersChallenge?.edges?.map((e) => e?.node),
  };
}
