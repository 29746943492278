import { formatISO, isAfter, parseISO, startOfDay, subWeeks } from 'date-fns';
import { gql, useQuery } from 'urql';

import { PlayerRewardStatus } from '@/globalTypes';
import { calculateNetLoss, calculateReward } from '@/utils';
import {
  PlayerRewardCalculatorQuery,
  PlayerRewardCalculatorQueryVariables,
} from './__generated__/usePlayerRewardCalculator';

const QUERY = gql`
  query PlayerRewardCalculator($playerId: ID!) {
    player(playerId: $playerId) {
      id
      playerStats {
        currency
        playerCurrentSegment
        ngr
        lastBonusAt
        ngrSinceLastManualReward
      }
      playerRewards {
        edges {
          node {
            assignedAt
            status
          }
        }
      }
    }
  }
`;

const doesPlayerHaveActiveBonus = (
  playerRewards: PlayerRewardCalculatorQuery['player']['playerRewards'],
) => {
  const startOfDayDate = startOfDay(new Date());

  const creditedBonus = playerRewards?.edges?.find(
    (edge) =>
      edge?.node.status === PlayerRewardStatus.Credited ||
      edge?.node.status === PlayerRewardStatus.Unclaimed,
  );

  const assignedAt = creditedBonus?.node.assignedAt;

  return isAfter(assignedAt, startOfDayDate);
};

const checkIfEligibleForReward = (
  playerStats: PlayerRewardCalculatorQuery['player']['playerStats'],
  lastBonusWasIn24h: boolean,
) => {
  if (!playerStats?.playerCurrentSegment) {
    return false;
  }

  if (playerStats?.playerCurrentSegment === 'Tier1') {
    return false;
  }

  if (lastBonusWasIn24h) {
    return false;
  }

  if (playerStats.ngr < 100) {
    return false;
  }

  if (playerStats.ngrSinceLastManualReward < 100) {
    return false;
  }

  return true;
};

export const usePlayerRewardCalculator = (playerId: string) => {
  const dateNow = new Date();
  const dateSixWeeksAgo = subWeeks(dateNow, 6);

  const [{ data, fetching }] = useQuery<
    PlayerRewardCalculatorQuery,
    PlayerRewardCalculatorQueryVariables
  >({
    query: QUERY,
    variables: {
      playerId,
    },
    requestPolicy: 'cache-and-network',
  });

  const playerStats = data?.player.playerStats ?? null;
  const playerRewards = data?.player.playerRewards ?? null;
  const playerCurrentSegment = playerStats?.playerCurrentSegment || '1';
  const ngr = playerStats?.ngr || 0;
  const lastBonusAt = playerStats?.lastBonusAt || null;
  const ngrSinceLastManualReward = playerStats?.ngrSinceLastManualReward || 0;

  // Parse the date strings using parseISO
  const parsedLastBonusAt = lastBonusAt ? parseISO(lastBonusAt.toString()) : 0;
  const parsedDateSixWeeksAgo = parseISO(formatISO(dateSixWeeksAgo));

  const lastBonusWasToday = playerRewards
    ? doesPlayerHaveActiveBonus(playerRewards)
    : false;

  const isEligibleForReward = checkIfEligibleForReward(
    playerStats,
    lastBonusWasToday,
  );

  const shouldUseLastBonusDate = isAfter(
    parsedLastBonusAt,
    parsedDateSixWeeksAgo,
  );

  const netLoss = calculateNetLoss(
    shouldUseLastBonusDate,
    ngr,
    ngrSinceLastManualReward,
  );

  const getRewardValues = calculateReward(playerCurrentSegment, netLoss);

  return {
    data: {
      ...getRewardValues,
      playerStats,
      lastBonusWasToday,
      isEligibleForReward,
    },
    fetching,
  };
};
