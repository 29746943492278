import { Cache, UpdateResolver } from '@urql/exchange-graphcache';

import { PlayerDocumentsQuery } from '@/blocks/player-documents-block/__generated__/usePlayerDocuments';
import { PLAYER_DOCUMENTS_QUERY } from '@/blocks/player-documents-block/usePlayerDocuments';
import {
  FundingDocumentUploadedMutation,
  UploadDocumentMutation,
} from '@/forms/player-document-upload-form/__generated__/PlayerDocumentUploadForm';

const getPlayerFromCache = (cache: Cache, playerId: string) => {
  return cache
    .inspectFields('Query')
    .find(
      (f) => f.fieldName === 'player' && f.arguments?.playerId === playerId,
    );
};

const inspectPlayerFields = (cache: Cache, playerId: string) => {
  return cache.inspectFields({
    __typename: 'Player',
    id: playerId,
  });
};

const prependToPlayerDocuments = (
  cache: Cache,
  playerId: string,
  node: NonNullable<
    NonNullable<PlayerDocumentsQuery['player']['documents']['edges']>[number]
  >['node'],
) => {
  const player = getPlayerFromCache(cache, playerId);

  if (player) {
    inspectPlayerFields(cache, playerId)
      .filter((f) => f.fieldName === 'documents')
      .forEach((f) => {
        cache.updateQuery<PlayerDocumentsQuery, any>(
          {
            query: PLAYER_DOCUMENTS_QUERY,
            variables: { ...player.arguments, ...f.arguments },
          },
          (data) => {
            if (data) {
              data.player.documents.edges = [
                {
                  __typename: 'DocumentEdge',
                  node,
                },
                ...(data.player.documents.edges ?? []),
              ];
            }
            return data;
          },
        );
      });
  }
};

const fundingDocumentUploaded: UpdateResolver<
  FundingDocumentUploadedMutation,
  any
> = (parent, args, cache) =>
  prependToPlayerDocuments(
    cache,
    args.playerId,
    parent.fundingDocumentUploaded,
  );

const uploadDocumentV2: UpdateResolver<UploadDocumentMutation, any> = (
  parent,
  args,
  cache,
) => prependToPlayerDocuments(cache, args.playerId, parent.uploadDocumentV2);

type MutationUpdates = {
  fundingDocumentUploaded: UpdateResolver<
    FundingDocumentUploadedMutation,
    {
      playerId: string;
    }
  >;
  uploadDocumentV2: UpdateResolver<
    UploadDocumentMutation,
    {
      playerId: string;
    }
  >;
};

export const urqlMutationUpdates: MutationUpdates = {
  fundingDocumentUploaded,
  uploadDocumentV2,
};
