import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { useQuery } from 'urql';

import { BooleanValue } from '@/bits';
import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  ControlledModal,
  InlineIconButton,
  Value,
} from '@/components';
import { EditIcon, InformationIcon, RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import { PlayerUpdateGamanzaStatusForm } from '@/forms';
import {
  PlayerGamanzaOverviewBoxQuery,
  PlayerGamanzaOverviewBoxQueryVariables,
} from './__generated__/component';
import GamanzaPlayerStatusDetails from './GamanzaPlayerStatusDetails';

export const Fragment = graphql`
  fragment SanityPlayerGamanzaOverviewBlock on SanityPlayerGamanzaOverviewBlock {
    title {
      ...SanityLocaleString
    }
  }
`;

const QUERY = gql`
  query PlayerGamanzaOverviewBox($playerId: ID!) {
    player(playerId: $playerId) {
      id
      getGamanzaPlayerBlockStatus {
        isBlocked
        updatedAt
        updatedBy {
          ... on AgentInitiator {
            agent {
              firstName
              lastName
            }
          }
        }
      }
      playerGamificationDetails {
        tokens
        rank
        level
        xpPoints
      }
    }
  }
`;

const PlayerGamanzaOverviewBlock: FC<{
  block: Queries.SanityPlayerGamanzaOverviewBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const { playerId } = useParams();

  const [{ data, fetching }, refresh] = useQuery<
    PlayerGamanzaOverviewBoxQuery,
    PlayerGamanzaOverviewBoxQueryVariables
  >({
    query: QUERY,
    variables: {
      playerId,
    },
  });

  const gamanzaPlayerBlockStatus = data?.player.getGamanzaPlayerBlockStatus;

  return (
    <Card
      size="lg"
      title={t(block.title)}
      options={
        <CardOptions>
          <CardOptionsButton
            className="flex"
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
          <BooleanValue
            fetching={fetching}
            title="Gamanza Blocked"
            value={gamanzaPlayerBlockStatus?.isBlocked}
            suffix={
              playerId && (
                <>
                  <ControlledModal
                    content={
                      <PlayerUpdateGamanzaStatusForm
                        playerId={playerId}
                        blockPlayer={
                          gamanzaPlayerBlockStatus?.isBlocked ?? false
                        }
                      />
                    }
                  >
                    <InlineIconButton tippyContent="Edit status">
                      <EditIcon />
                    </InlineIconButton>
                  </ControlledModal>
                  <ControlledModal
                    content={
                      gamanzaPlayerBlockStatus ? (
                        <GamanzaPlayerStatusDetails
                          {...gamanzaPlayerBlockStatus}
                        />
                      ) : null
                    }
                  >
                    <InlineIconButton tippyContent="Status details">
                      <InformationIcon />
                    </InlineIconButton>
                  </ControlledModal>
                </>
              )
            }
          />
          <Value
            fetching={fetching}
            title="Tokens"
            value={data?.player.playerGamificationDetails?.tokens}
          />
          <Value
            fetching={fetching}
            title="Rank"
            value={data?.player.playerGamificationDetails?.rank}
          />
          <Value
            fetching={fetching}
            title="Level"
            value={data?.player.playerGamificationDetails?.level}
          />
          <Value
            fetching={fetching}
            title="XP Points"
            value={data?.player.playerGamificationDetails?.xpPoints}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerGamanzaOverviewBlock;
