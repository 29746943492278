import gql from 'graphql-tag';
import { useQuery } from 'urql';
import { useQueryParams } from 'use-query-params';

import { GameDescriptor } from '@/fragments/GameDescriptor';
import { mapVariables } from '@/utils';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  RewardDetailQuery,
  RewardDetailQueryVariables,
} from './__generated__/useRewardDetail';
import { queryParams } from './queryParams';

const REWARD_DETAIL_QUERY = gql`
  query RewardDetail(
    $id: String!
    $updatedAtFrom: OffsetDateTime
    $updatedAtTo: OffsetDateTime
    $rewardStatus: PlayerRewardStatus
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    viewer {
      id
      reward(id: $id) {
        name
        createdAt
        startsAt
        endsAt
        status
        campaignId
        comments
        createdBy {
          firstName
          lastName
        }
        rewardSystem
        rewardType {
          __typename
          name
          ... on FreeSpinReward {
            betLevel
            gameDescriptor: gameDescriptorV2 {
              ...GameDescriptor
            }
            freeSpinsAmount: amount
            value
            currency
          }
          ... on MoneyDropReward {
            moneyDropAmount: amount
            currency
          }
          ... on BonusMoneyDropReward {
            bonusMoneyDropAmount: amount
            currency
            maxPayout
            wageringRequirementFactor
          }
        }
        players(
          updatedAtFrom: $updatedAtFrom
          updatedAtTo: $updatedAtTo
          rewardStatus: $rewardStatus
          first: $first
          last: $last
          after: $after
          before: $before
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            node {
              id
              playerId
              status
              updatedAt
              reason
              agent {
                firstName
                lastName
              }
              assignationId
            }
          }
        }
      }
    }
  }
  ${GameDescriptor}
`;

export default function useRewardDetail(id: string) {
  const [query, setQuery] = useQueryParams(queryParams);
  const [{ data, fetching }, refresh] = useQuery<
    RewardDetailQuery,
    RewardDetailQueryVariables
  >({
    query: REWARD_DETAIL_QUERY,
    variables: {
      first: 10,
      ...mapVariables(query),
      id,
    },
    requestPolicy: 'cache-and-network',
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makeOrderBy(query, setQuery),
    ...makePagination(query, setQuery, data?.viewer.reward?.players?.pageInfo),
    reward: data?.viewer.reward,
    rewardPlayers: data?.viewer.reward?.players?.edges?.map((e) => e?.node),
  };
}
