import classNames from 'classnames';
import React, { ComponentProps } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';

import { BaseWrapper } from './BaseWrapper';

export type BaseInputFieldProps = {
  title: string;
  name: string;
  required?: boolean;
  className?: string;
  id?: string;
  step?: ComponentProps<'input'>['step'];
  disabled?: boolean;
};

export const BaseInputField = ({
  title,
  name,
  type = 'text',
  required,
  className,
  id,
  disabled,
  ...rest
}: BaseInputFieldProps & ComponentProps<'input'> & RegisterOptions) => {
  const { register } = useFormContext();
  return (
    <BaseWrapper
      id={id}
      title={title}
      name={name}
      className={className}
      required={required}
    >
      <input
        className={classNames(
          'mt-1 focus:outline-none focus:ring block w-full sm:text-sm border-gray-300 rounded-md',
          {
            'bg-gray-300 dark:bg-gray-500 dark:border-gray-500': disabled,
          },
        )}
        id={id ?? name}
        type={type}
        placeholder={title}
        autoComplete="off"
        disabled={disabled}
        {...rest}
        {...register(name, { required, ...rest })}
      />
    </BaseWrapper>
  );
};
