import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { useMutation } from 'urql';

import {
  Card,
  CardCloseButton,
  CardOptions,
  ErrorMessage,
  Form,
  NumberFormatField,
  SubmitButton,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { PeriodLimitFragment } from '@/fragments/__generated__/Limits';
import { limitFragment } from '@/fragments/Limits';
import { LimitTypeEnum } from '@/globalTypes';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import {
  ChangePlayerWithdrawalLimitMutation,
  ChangePlayerWithdrawalLimitMutationVariables,
} from './__generated__/PlayerWithdrawalLimitsForm';

const query = graphql`
  query SanityPlayerWithdrawalLimitsForm {
    sanityPlayerWithdrawalLimitsForm {
      title {
        ...SanityLocaleString
      }
    }
  }
`;

const changeWithdrawalLimitMutation = gql`
  mutation ChangePlayerWithdrawalLimit(
    $playerId: ID!
    $amount: PositiveBigDecimal!
    $limitPeriodType: LimitTypeEnum!
  ) {
    setWithdrawalLimit(
      amount: $amount
      playerId: $playerId
      limitPeriodType: $limitPeriodType
    ) {
      id
      withdrawalLimit {
        dayLimit {
          ...PeriodLimit
        }
        weekLimit {
          ...PeriodLimit
        }
        monthLimit {
          ...PeriodLimit
        }
      }
    }
  }
  ${limitFragment}
`;

export const getLimitPeriodTypeLabel = (
  limitPeriodType: LimitTypeEnum,
): string => {
  switch (limitPeriodType) {
    case LimitTypeEnum.Day:
      return 'Daily';
    case LimitTypeEnum.Week:
      return 'Weekly';
    case LimitTypeEnum.Month:
      return 'Monthly';
    default:
      return limitPeriodType;
  }
};

const PlayerWithdrawalLimitsForm: FC<{
  playerId: string;
  limitPeriodType: LimitTypeEnum;
  limit: Nullable<PeriodLimitFragment>;
  currency: Nullable<string>;
}> = ({ playerId, limit, limitPeriodType, currency }) => {
  const { close } = useModalContext();
  const { t } = useTranslate();

  const staticData =
    useStaticQuery<Queries.SanityPlayerWithdrawalLimitsFormQuery>(query);

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();

  const form = staticData.sanityPlayerWithdrawalLimitsForm;

  const [changePlayerWithdrawalLimitState, changePlayerWithdrawalLimit] =
    useMutation<
      ChangePlayerWithdrawalLimitMutation,
      ChangePlayerWithdrawalLimitMutationVariables
    >(changeWithdrawalLimitMutation);

  const defaultValues = {
    amount: limit?.value,
  };

  const onSubmit = (values: typeof defaultValues) => {
    if (playerId) {
      changePlayerWithdrawalLimit({
        playerId,
        amount: values.amount ?? 0,
        limitPeriodType,
      }).then((res) => {
        if (res.error?.message && isMounted) {
          setErrorMessage(res.error?.message);
        } else if (close) {
          close();
        }
      });
    }
  };

  if (!form || !defaultValues) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        <Form
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          className="grid grid-cols-2 sm:grid-cols-3 gap-6"
        >
          <NumberFormatField
            title={getLimitPeriodTypeLabel(limitPeriodType)}
            suffix={` ${currency}`}
            name="amount"
            id="PlayerWithdrawalLimitsForm__amount"
            className="col-span-full"
          />
          <ErrorMessage message={errorMessage} />
          <SubmitButton
            value="Submit"
            disabled={changePlayerWithdrawalLimitState.fetching}
          />
        </Form>
      </div>
    </Card>
  );
};

export default PlayerWithdrawalLimitsForm;
