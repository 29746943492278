import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { gql, useQuery } from 'urql';

import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  ControlledModal,
  InlineIconButton,
  OptionsMenu,
  Value,
} from '@/components';
import { EditIcon, InformationIcon, RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import {
  PlayerAddStatusOverwriteForm,
  PlayerRemoveStatusOverwriteForm,
} from '@/forms';
import { isNotNullOrUndefined } from '@/utils';
import { useCan } from '@/utils/access';
import {
  PlayerScreeningStatusesQuery,
  PlayerScreeningStatusesQueryVariables,
} from './__generated__/components';
import { ScreeningDetails_OverwritableStatusCheckFragment } from './__generated__/ScreeningDetails';
import ScreeningDetails, {
  screeningDetails_overwritableStatusCheck,
} from './ScreeningDetails';

export const Fragment = graphql`
  fragment SanityPlayerScreeningStatusesBlock on SanityPlayerScreeningStatusesBlock {
    title {
      ...SanityLocaleString
    }
  }
`;

const query = gql`
  query PlayerScreeningStatuses($playerId: ID!) {
    player(playerId: $playerId) {
      id
      screeningStatuses {
        pep {
          ...ScreeningDetails_OverwritableStatusCheck
        }
        fraud {
          ...ScreeningDetails_OverwritableStatusCheck
        }
        sanctioned {
          ...ScreeningDetails_OverwritableStatusCheck
        }
      }
    }
  }
  ${screeningDetails_overwritableStatusCheck}
`;

export type ScreeningType = 'pep' | 'fraud' | 'sanctioned';

type ScreeningValueProps = {
  title: string;
  screeningStatus: ScreeningDetails_OverwritableStatusCheckFragment | undefined;
  screeningType: ScreeningType;
  playerId: string | null;
  fallback: () => void;
  accessDenied: boolean;
  fetching?: boolean;
};

const getScreeningStatus = (
  screeningStatus: ScreeningDetails_OverwritableStatusCheckFragment | undefined,
) => {
  if (screeningStatus && isNotNullOrUndefined(screeningStatus.finalDecision)) {
    return screeningStatus.finalDecision ? 'Yes' : 'No';
  }

  return screeningStatus?.overwrite?.decision ? 'Yes' : 'No';
};

const ScreeningValue: FC<ScreeningValueProps> = ({
  title,
  screeningStatus,
  screeningType,
  playerId,
  fetching,
  fallback,
  accessDenied,
}) => (
  <Value
    title={
      <div className="flex">
        <span>{title}</span>
        <ControlledModal
          content={
            screeningStatus && (
              <ScreeningDetails
                title={`${title} Details`}
                screening={screeningStatus}
              />
            )
          }
        >
          <InlineIconButton>
            <InformationIcon />
          </InlineIconButton>
        </ControlledModal>
      </div>
    }
    value={getScreeningStatus(screeningStatus)}
    fetching={fetching}
    suffix={
      !accessDenied && (
        <OptionsMenu
          items={[
            {
              content: 'Add Overwrite',
              modalContent: playerId && (
                <PlayerAddStatusOverwriteForm
                  playerId={playerId}
                  screeningType={screeningType}
                  fallback={fallback}
                />
              ),
              icon: <EditIcon />,
            },
            ...(screeningStatus?.overwrite
              ? [
                  {
                    content: 'Remove Overwrite',
                    modalContent: playerId && (
                      <PlayerRemoveStatusOverwriteForm
                        playerId={playerId}
                        screeningType={screeningType}
                        fallback={fallback}
                      />
                    ),
                    icon: <EditIcon />,
                  },
                ]
              : []),
          ]}
        />
      )
    }
  />
);

const PlayerScreeningStatusesBlock: FC<{
  block: Queries.SanityPlayerScreeningStatusesBlockFragment;
}> = ({ block }) => {
  const { playerId } = useParams();
  const { t } = useTranslate();
  const isAgentAllowedToAccessKyc6 = useCan('MANAGE_AND_ACCESS_KYC6');

  const [{ data, fetching }, refresh] = useQuery<
    PlayerScreeningStatusesQuery,
    PlayerScreeningStatusesQueryVariables
  >({
    query,
    variables: {
      playerId,
    },
  });

  const screeningStatuses = data?.player.screeningStatuses;

  return (
    <Card
      size="lg"
      title={t(block.title)}
      options={
        <CardOptions>
          <CardOptionsButton
            className="flex"
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <div className="grid gap-3 p-3 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
          <ScreeningValue
            title="Pep"
            screeningStatus={screeningStatuses?.pep}
            screeningType="pep"
            playerId={playerId}
            fallback={() => refresh({ requestPolicy: 'network-only' })}
            fetching={fetching}
            accessDenied={!isAgentAllowedToAccessKyc6}
          />
          <ScreeningValue
            title="Sanctioned"
            screeningStatus={screeningStatuses?.sanctioned}
            screeningType="sanctioned"
            playerId={playerId}
            fallback={() => refresh({ requestPolicy: 'network-only' })}
            fetching={fetching}
            accessDenied={!isAgentAllowedToAccessKyc6}
          />
          <ScreeningValue
            title="Fraud"
            screeningStatus={screeningStatuses?.fraud}
            screeningType="fraud"
            playerId={playerId}
            fallback={() => refresh({ requestPolicy: 'network-only' })}
            fetching={fetching}
            accessDenied={!isAgentAllowedToAccessKyc6}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerScreeningStatusesBlock;
