export const returnMultipleValues = (values: string[]) => {
  if (!values.length) {
    return '-';
  }

  if (values.length < 2) {
    return values[0];
  }

  return `${values[0]} (${values.length})`;
};
