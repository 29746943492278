import {
  BooleanParam,
  DateTimeParam,
  NumberParam,
  StringParam,
  withDefault,
} from 'use-query-params';

import { KYC6MatchesOrderBy } from '@/globalTypes';
import {
  KYC6FilteringMatchDecisionParam,
  KYC6MatchTypesParam,
  KYC6MatchesOrderParam,
} from '@/utils/query/params';

export const queryParams = {
  aboutToExpireThresholdInDays: NumberParam,
  after: StringParam,
  before: StringParam,
  desc: withDefault(BooleanParam, true),
  first: withDefault(NumberParam, 20),
  last: NumberParam,
  kyc6MatchTypes: KYC6MatchTypesParam,
  matchDecision: KYC6FilteringMatchDecisionParam,
  lastSearchedAtFrom: DateTimeParam,
  lastSearchedAtTo: DateTimeParam,
  orderBy: withDefault(
    KYC6MatchesOrderParam,
    KYC6MatchesOrderBy.LastSearchedAt,
  ),
};
