import React, { FC } from 'react';

import { LimitPeriodDetailList } from '@/bits';
import { LimitBar } from '@/components';
import {
  PlayerRemoveWithdrawalLimitForm,
  PlayerWithdrawalLimitsForm,
} from '@/forms';
import { LimitType, LimitTypeEnum } from '@/globalTypes';
import { Nullable } from '@/types';
import { useCan } from '@/utils/access';
import { PlayerWithdrawalLimitsBoxQuery } from './__generated__/component';

type WithdrawalLimitBarsProps = {
  wallet: PlayerWithdrawalLimitsBoxQuery['player']['wallet'] | undefined;
  fetching: boolean;
  playerId: string;
  dailyLimitValue: string;
  monthlyLimitValue: string;
  currencyValueFormatter: (value: Nullable<number>) => string;
};

const WithdrawalLimitBars: FC<WithdrawalLimitBarsProps> = ({
  wallet,
  fetching,
  playerId,
  dailyLimitValue,
  monthlyLimitValue,
  currencyValueFormatter,
}) => {
  const withdrawLimit = wallet?.withdrawalLimit;

  const isAgentAllowedToChangeOrRemove = useCan(
    'CHANGE_AND_REMOVE_WITHDRAWAL_LIMITS',
  );

  return (
    <div className="grid gap-2 pt-1">
      <LimitBar
        currency={wallet?.currency}
        fetching={fetching}
        limit={withdrawLimit?.dayLimit}
        value={dailyLimitValue}
        disableChangeLimit={!isAgentAllowedToChangeOrRemove}
        disableRemoveLimit={!isAgentAllowedToChangeOrRemove}
        moreDetails={
          <LimitPeriodDetailList
            limit={withdrawLimit?.dayLimit}
            limitTypeTitle={LimitType.Withdraw}
            limitPeriodType={LimitTypeEnum.Day}
            formatValue={currencyValueFormatter}
          />
        }
        changeLimit={
          <PlayerWithdrawalLimitsForm
            playerId={playerId}
            limitPeriodType={LimitTypeEnum.Day}
            currency={wallet?.currency}
            limit={withdrawLimit?.dayLimit}
          />
        }
        removeLimit={
          <PlayerRemoveWithdrawalLimitForm
            playerId={playerId}
            limitPeriodType={LimitTypeEnum.Day}
          />
        }
      />
      <LimitBar
        currency={wallet?.currency}
        fetching={fetching}
        limit={withdrawLimit?.monthLimit}
        value={monthlyLimitValue}
        disableChangeLimit={!isAgentAllowedToChangeOrRemove}
        disableRemoveLimit={!isAgentAllowedToChangeOrRemove}
        moreDetails={
          <LimitPeriodDetailList
            limit={withdrawLimit?.monthLimit}
            limitTypeTitle={LimitType.Withdraw}
            limitPeriodType={LimitTypeEnum.Month}
            formatValue={currencyValueFormatter}
          />
        }
        changeLimit={
          <PlayerWithdrawalLimitsForm
            playerId={playerId}
            limitPeriodType={LimitTypeEnum.Month}
            currency={wallet?.currency}
            limit={withdrawLimit?.monthLimit}
          />
        }
        removeLimit={
          <PlayerRemoveWithdrawalLimitForm
            playerId={playerId}
            limitPeriodType={LimitTypeEnum.Month}
          />
        }
      />
    </div>
  );
};

export default WithdrawalLimitBars;
