import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useCallback } from 'react';
import { feedback } from 'react-feedbacker';
import { useQuery } from 'urql';

import { useFeedbackMessages } from '@/bits';
import { limitPeriodDetailList_periodLimit } from '@/bits/limit-period-detail-list/component';
import { Card, CardBody, CardOptions, CardOptionsButton } from '@/components';
import { LockClosedIcon, LockOpenIcon, RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import { limitBlockingFragment, limitFragment } from '@/fragments/Limits';
import { Nullable, Unwrap } from '@/types';
import { useCan } from '@/utils/access';
import formatMoney from '@/utils/formatter/formatMoney';
import {
  PlayerDepositLimitsBoxQuery,
  PlayerDepositLimitsBoxQueryVariables,
} from './__generated__/component';
import { useBlockDepositLimit, useUnblockDepositLimit } from './blocking';
import DepositLimitBars from './DepositLimitBars';

export const Fragment = graphql`
  fragment SanityPlayerDepositLimitsBlock on SanityPlayerDepositLimitsBlock {
    title {
      ...SanityLocaleString
    }
    depositLimitsLabel {
      ...SanityLocaleString
    }
    dailyLabel {
      ...SanityLocaleString
    }
    weeklyLabel {
      ...SanityLocaleString
    }
    monthlyLabel {
      ...SanityLocaleString
    }
  }
`;

const QUERY = gql`
  query PlayerDepositLimitsBox($playerId: ID!) {
    player(playerId: $playerId) {
      id
      wallet {
        id
        currency
        depositLimit {
          ...LimitBlockingStatus
          dayLimit {
            ...PeriodLimit
            ...LimitPeriodDetailList_periodLimit
          }
          weekLimit {
            ...PeriodLimit
            ...LimitPeriodDetailList_periodLimit
          }
          monthLimit {
            ...PeriodLimit
            ...LimitPeriodDetailList_periodLimit
          }
        }
      }
    }
  }
  ${limitFragment}
  ${limitBlockingFragment}
  ${limitPeriodDetailList_periodLimit}
`;

const PlayerDepositLimitsBlock: FC<{
  block: Queries.SanityPlayerDepositLimitsBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const { playerId } = useParams();
  const feedbackMessages = useFeedbackMessages();

  const [{ data, fetching }, refresh] = useQuery<
    PlayerDepositLimitsBoxQuery,
    PlayerDepositLimitsBoxQueryVariables
  >({
    query: QUERY,
    variables: {
      playerId,
    },
  });

  const [blockLimitState, blockLimit] = useBlockDepositLimit();
  const [unblockLimitState, unblockLimit] = useUnblockDepositLimit();

  const canBlock = useCan('BLOCK_PLAYER_LIMITS');
  const canUnblock = useCan('BLOCK_PLAYER_LIMITS');

  const onBlockMutationCompleted = (
    res: Unwrap<ReturnType<typeof blockLimit | typeof unblockLimit>>,
  ) => {
    if (res.error?.message) {
      feedback.error(res.error.message);
    } else {
      feedback.success(t(feedbackMessages.success));
    }
  };

  const loadingBlockMutation =
    blockLimitState.fetching || unblockLimitState.fetching;

  const wallet = data?.player.wallet;
  const depositLimit = wallet?.depositLimit;
  const isBlocked = depositLimit?.blockingStatus?.blocked;

  const canSwitchBlocking = isBlocked ? canUnblock : canBlock;

  const onBlockToggle = () => {
    if (!canSwitchBlocking) {
      return;
    }

    if (isBlocked) {
      unblockLimit({ playerId }).then(onBlockMutationCompleted);
      return;
    }

    blockLimit({ playerId }).then(onBlockMutationCompleted);
  };

  const currencyValueFormatter = useCallback(
    (value: Nullable<number>) => formatMoney(value, wallet?.currency),
    [wallet?.currency],
  );

  const dailyLimitValue = t(block.dailyLabel, {
    value: formatMoney(
      (depositLimit?.dayLimit?.value ?? 0) -
        (depositLimit?.dayLimit?.available ?? 0),
    ),
    of: formatMoney(depositLimit?.dayLimit?.value, wallet?.currency) || '-',
  });

  const weeklyLimitValue = t(block.weeklyLabel, {
    value: formatMoney(
      (depositLimit?.weekLimit?.value ?? 0) -
        (depositLimit?.weekLimit?.available ?? 0),
    ),
    of: formatMoney(depositLimit?.weekLimit?.value, wallet?.currency) || '-',
  });

  const monthlyLimitValue = t(block.monthlyLabel, {
    value: formatMoney(
      (depositLimit?.monthLimit?.value ?? 0) -
        (depositLimit?.monthLimit?.available ?? 0),
    ),
    of: formatMoney(depositLimit?.monthLimit?.value, wallet?.currency) || '-',
  });

  return (
    <Card
      size="lg"
      title={t(block.title)}
      options={
        <CardOptions>
          <CardOptionsButton
            type="button"
            disabled={loadingBlockMutation || !canSwitchBlocking}
            onClick={onBlockToggle}
          >
            {isBlocked ? <LockClosedIcon /> : <LockOpenIcon />}
          </CardOptionsButton>
          <CardOptionsButton
            className="flex"
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
          <div className="col-span-full">
            <label className="text-sm text-gray-500 dark:text-gray-300 font-semibold">
              {t(block.depositLimitsLabel)}
            </label>
            <DepositLimitBars
              wallet={wallet}
              fetching={fetching}
              playerId={playerId}
              dailyLimitValue={dailyLimitValue}
              weeklyLimitValue={weeklyLimitValue}
              monthlyLimitValue={monthlyLimitValue}
              currencyValueFormatter={currencyValueFormatter}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerDepositLimitsBlock;
