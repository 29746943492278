import {
  BooleanParam,
  DateTimeParam,
  NumberParam,
  StringParam,
  withDefault,
} from 'use-query-params';

export const queryParams = {
  after: StringParam,
  before: StringParam,
  desc: withDefault(BooleanParam, true),
  first: withDefault(NumberParam, 20),
  last: NumberParam,
  from: DateTimeParam,
  to: DateTimeParam,
  playerUUID: StringParam,
};
