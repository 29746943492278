import classNames from 'classnames';
import React, { FC, ReactNode, useState } from 'react';

type FieldsetWrapperProps = {
  legend: string;
  children: ReactNode;
};

export const FieldsetWrapper: FC<FieldsetWrapperProps> = ({
  legend,
  children,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <fieldset
      className={classNames('border border-gray-700 p-4 rounded-md', {
        'border-none': !isVisible,
      })}
    >
      <legend
        onClick={toggleVisibility}
        className="text-sm text-gray-500 flex items-center cursor-pointer"
      >
        <span
          className={classNames('transform transition-transform', {
            'rotate-90': isVisible,
          })}
        >
          &#9654;
        </span>
        <span className="ml-1">{legend}</span>
      </legend>
      {isVisible && <div className="space-y-2">{children}</div>}
    </fieldset>
  );
};
