import { useParams } from '@reach/router';
import classNames from 'classnames';
import { Link, graphql } from 'gatsby';
import gql from 'graphql-tag';
import { startCase } from 'lodash';
import React, { FC, useMemo } from 'react';
import { MdOutlineErrorOutline } from 'react-icons/md';
import { useQuery } from 'urql';

import { useGetPlayerOverviewPageLink } from '@/bits/links/useLink';
import {
  Card,
  CardBody,
  CardCloseButton,
  CardOptions,
  CardOptionsButton,
  ControlledModal,
  Copy,
  Country,
  Date as DateComponent,
  GlobalPlayerBrandValues,
  InlineIconButton,
  Table,
  Value,
} from '@/components';
import { AccountStatus } from '@/components/AccountStatus';
import { GlobalPlayerSelfExclusionValues } from '@/components/GlobalPlayerSelfExclusionValues';
import { EyeIcon, RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import { AccountStatusEnum, CloseAccountCauseV4 } from '@/globalTypes';
import {
  getGenericBrandsValues,
  getRgRiskLevelColor,
  getSelfExclusionBrandsValues,
} from '@/utils';
import formatCountryCode from '@/utils/formatter/formatCountryCode';
import formatPhoneNumber from '@/utils/formatter/formatPhoneNumber';
import { splitStringIntoTwoWords } from '@/utils/split-string-in-two-words';
import {
  GlobalPlayerInfoBoxQuery,
  GlobalPlayerInfoBoxQueryVariables,
} from './__generated__/component';

export const Fragment = graphql`
  fragment SanityGlobalPlayerInfoBlock on SanityGlobalPlayerInfoBlock {
    title {
      ...SanityLocaleString
    }
    phoneNumber {
      ...SanityLocaleString
    }
    email {
      ...SanityLocaleString
    }
    edit {
      ...SanityLocaleString
    }
    resendVerificationEmail {
      ...SanityLocaleString
    }
    couldNotResendVerificationEmail {
      ...SanityLocaleString
    }
    country {
      ...SanityLocaleString
    }
    language {
      ...SanityLocaleString
    }
    accountStatus {
      ...SanityLocaleString
    }
    accountStatusOpen {
      ...SanityLocaleString
    }
    accountStatusClosed {
      ...SanityLocaleString
    }
    accountWillReopen {
      ...SanityLocaleString
    }
    changeAccountStatus {
      ...SanityLocaleString
    }
    withdrawalsStatus {
      ...SanityLocaleString
    }
    withdrawalsStatusBlocked {
      ...SanityLocaleString
    }
    withdrawalsStatusNotBlocked {
      ...SanityLocaleString
    }
    sowBlockedStatus {
      ...SanityLocaleString
    }
    sowNotBlocked {
      ...SanityLocaleString
    }
    sowBlocked {
      ...SanityLocaleString
    }
    selfExclusion {
      ...SanityLocaleString
    }
    excludedUntil {
      ...SanityLocaleString
    }
    willBeCancelledAt {
      ...SanityLocaleString
    }
    lastLogin {
      ...SanityLocaleString
    }
    pendingEnding {
      ...SanityLocaleString
    }
    indefinite {
      ...SanityLocaleString
    }
  }
`;

const query = gql`
  query GlobalPlayerInfoBox($playerGlobalId: ID!) {
    playerGlobal(playerGlobalId: $playerGlobalId) {
      __typename
      id
      playerGlobalUUID
      players {
        __typename
        id
        rawPlayerId
        brand {
          __typename
          id
          name
        }
        email
        phoneNumber
        countryCode
        accountStatus {
          __typename
          status
          cause
        }
        areWithdrawalsBlocked
        shouldReopenAt
        sowBlockedStatus
        selfExclusionDetails {
          __typename
          configuredAt
          exclusionEndsAt
        }
        loginInfo {
          __typename
          lastLogin {
            __typename
            loggedAt
            countryCode
          }
          registration {
            __typename
            loggedAt
          }
        }
      }
      rgCraCustomerRiskAssessment {
        riskLevel
      }
    }
  }
`;

const GlobalPlayerInfoBlock: FC<{
  block: Queries.SanityGlobalPlayerInfoBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const { playerGlobalId } = useParams();
  const getPlayerOverviewLink = useGetPlayerOverviewPageLink();

  const isMGA = process.env.GATSBY_LICENSE === 'MGA';

  const [{ data, fetching }, refresh] = useQuery<
    GlobalPlayerInfoBoxQuery,
    GlobalPlayerInfoBoxQueryVariables
  >({
    query,
    variables: {
      playerGlobalId,
    },
  });

  const firstPlayer = data?.playerGlobal.players[0];

  const getPlayersAccountStatus =
    data &&
    getGenericBrandsValues(data, (entry) => entry.accountStatus.status)?.map(
      (item) => item.brandValue,
    );

  const globalIsOpen = !!getPlayersAccountStatus?.includes('Open');
  const globalStatus = globalIsOpen
    ? AccountStatusEnum.Open
    : AccountStatusEnum.Closed;

  const globalCause = firstPlayer?.accountStatus.cause as CloseAccountCauseV4;

  const numberOfSelfExcludedAccounts = useMemo(() => {
    const currentDate = new Date();

    const selfExcludedPlayers = data?.playerGlobal.players.filter(
      ({ selfExclusionDetails }) => {
        if (!selfExclusionDetails) {
          return null;
        }

        const configuredAt = new Date(selfExclusionDetails.configuredAt);
        const exclusionEndsAt = new Date(selfExclusionDetails.exclusionEndsAt);

        const isInfiniteExclusion =
          configuredAt <= currentDate && !selfExclusionDetails.exclusionEndsAt;

        return (
          (configuredAt <= currentDate && exclusionEndsAt >= currentDate) ||
          isInfiniteExclusion
        );
      },
    );

    return selfExcludedPlayers?.length || null;
  }, [data]);

  return (
    <>
      <Card
        size="lg"
        title={
          <>
            {t(block.title)}{' '}
            <Copy
              fetching={fetching}
              value={data?.playerGlobal.playerGlobalUUID}
            />
          </>
        }
        options={
          <CardOptions>
            <ControlledModal
              content={
                <Card
                  title={'Brand players'}
                  size="lg"
                  options={
                    <CardOptions>
                      <CardCloseButton />
                    </CardOptions>
                  }
                >
                  <div className="p-3">
                    {data?.playerGlobal.players && (
                      <Table
                        headings={['Brand', 'Player']}
                        rows={data.playerGlobal.players.map((player) => {
                          return {
                            key: player.id,
                            data: [
                              player.brand.name,
                              <Link to={getPlayerOverviewLink(player.id) ?? ''}>
                                {player.rawPlayerId}
                              </Link>,
                            ],
                          };
                        })}
                      />
                    )}
                  </div>
                </Card>
              }
            >
              <InlineIconButton>
                <EyeIcon className="text-blue-400 text-lg" />
              </InlineIconButton>
            </ControlledModal>
            <CardOptionsButton
              className="flex"
              onClick={() => refresh({ requestPolicy: 'network-only' })}
            >
              <RefreshIcon />
            </CardOptionsButton>
          </CardOptions>
        }
      >
        <CardBody>
          {data && (
            <div className="p-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
              <Value
                fetching={fetching}
                title={t(block.phoneNumber)}
                value={formatPhoneNumber(firstPlayer?.phoneNumber, false)}
                suffix={
                  <GlobalPlayerBrandValues
                    title={t(block.phoneNumber)}
                    values={getGenericBrandsValues(
                      data,
                      (entry) => entry.phoneNumber,
                    )}
                  />
                }
              />
              <Value
                fetching={fetching}
                title={t(block.email)}
                value={firstPlayer?.email}
                suffix={
                  <GlobalPlayerBrandValues
                    title={t(block.email)}
                    values={getGenericBrandsValues(
                      data,
                      (entry) => entry.email,
                    )}
                  />
                }
              />
              <Value
                fetching={fetching}
                title={t(block.country)}
                value={firstPlayer?.countryCode}
                suffix={
                  <GlobalPlayerBrandValues
                    title={t(block.country)}
                    values={getGenericBrandsValues(
                      data,
                      (entry) => entry.countryCode,
                    )}
                  />
                }
              >
                <Country countryCode={firstPlayer?.countryCode} />
              </Value>
              <Value
                fetching={fetching}
                title={t(block.accountStatus)}
                value={firstPlayer?.accountStatus.status}
                suffix={
                  <GlobalPlayerBrandValues
                    title={t(block.accountStatus)}
                    values={getGenericBrandsValues(
                      data,
                      ({ accountStatus }) =>
                        (accountStatus.cause &&
                          startCase(accountStatus.cause)) ||
                        '-',
                    )}
                  />
                }
              >
                <AccountStatus
                  block={block}
                  playerId={playerGlobalId}
                  status={globalStatus}
                  cause={globalCause}
                />
              </Value>
              <Value fetching={fetching} title={t(block.lastLogin)}>
                {formatCountryCode(
                  firstPlayer?.loginInfo?.lastLogin?.countryCode,
                )}
                <DateComponent
                  copyable
                  date={firstPlayer?.loginInfo?.lastLogin?.loggedAt}
                />
              </Value>
              <Value
                fetching={fetching}
                title={t(block.selfExclusion)}
                value={numberOfSelfExcludedAccounts}
                hasCopy={false}
                suffix={
                  numberOfSelfExcludedAccounts && (
                    <GlobalPlayerSelfExclusionValues
                      title={t(block.selfExclusion)}
                      values={getSelfExclusionBrandsValues(data)}
                    />
                  )
                }
              />
              {isMGA && (
                <Value fetching={fetching} title={'RG CRA Risk'}>
                  <div className="flex gap-1 items-center">
                    <MdOutlineErrorOutline
                      className={classNames(
                        getRgRiskLevelColor(
                          data?.playerGlobal.rgCraCustomerRiskAssessment
                            ?.riskLevel,
                        ),
                      )}
                    />
                    <span>
                      {splitStringIntoTwoWords(
                        data?.playerGlobal.rgCraCustomerRiskAssessment
                          ?.riskLevel,
                      )}
                    </span>
                  </div>
                </Value>
              )}
            </div>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default GlobalPlayerInfoBlock;
