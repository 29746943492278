import { ComponentType, JSX, PropsWithChildren, lazy } from 'react';

type FileDriverProps = {
  filePath: string;
  fileType: string;
  onLoaded: () => void;
  isLoading: boolean;
};

export type FileDriver = (
  props: PropsWithChildren<FileDriverProps>,
) => JSX.Element | null;

const BackupDriver = lazy(() => import('./backup'));
const ImageDriver = lazy(() => import('./image'));
const PdfDriver = lazy(() => import('./pdf'));

export const getDriver = (fileType: string): ComponentType<FileDriverProps> => {
  switch (fileType) {
    case 'pdf':
      return PdfDriver;
    case 'jpg':
    case 'jpeg':
    case 'gif':
    case 'bmp':
    case 'png':
      return ImageDriver;
    default:
      return BackupDriver;
  }
};
