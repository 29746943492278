import React, { ReactNode } from 'react';

import {
  ConditionalWrap,
  ControlledModal,
  InlineIconButton,
  Tippy,
} from '@/components';
import { Bar } from '@/components/Bar';
import { CloseIcon, EditIcon, InformationIcon } from '@/components/icons';
import { TrashIcon } from '@/components/icons/TrashIcon';
import { Maybe } from '@/globalTypes';
import { Nullable } from '@/types';
import formatDate from '@/utils/formatter/formatDate';
import formatMoney from '@/utils/formatter/formatMoney';

type LimitProps = {
  value: number;
  available: number;
  currentPeriodEndTime: Maybe<string>;
  pendingPeriodLimit: Maybe<{
    configuredAt: string;
    validFrom: string;
    value: number;
  }>;
  pendingPeriodLimitRemoval: Maybe<{
    validFrom: string;
  }>;
  isDefault: boolean;
};

type LimitBarProps = {
  fetching: boolean;
  value: string;
  limit: Maybe<LimitProps | undefined>;
  currency: Nullable<string>;
  moreDetails?: ReactNode;
  cancelLimit?: ReactNode;
  changeLimit?: ReactNode;
  removeLimit?: ReactNode;
  disableMoreLimit?: boolean;
  disableChangeLimit?: boolean;
  disableRemoveLimit?: boolean;
};

const getLimitBarValue = (limit: Maybe<LimitProps | undefined>) => {
  const value = limit?.value ?? 0;
  const available = limit?.available ?? 0;
  return (value - available) / value;
};

export const LimitBar = ({
  fetching,
  value,
  limit,
  currency,
  changeLimit,
  removeLimit,
  moreDetails,
  cancelLimit,
  disableMoreLimit = false,
  disableChangeLimit = false,
  disableRemoveLimit = false,
}: LimitBarProps) => {
  return (
    <>
      <div className="flex">
        <ConditionalWrap
          condition={!!limit}
          wrap={(children) => (
            <Tippy
              content={`Period ending: ${formatDate(
                limit?.currentPeriodEndTime,
              )}`}
            >
              <div className="w-full">{children}</div>
            </Tippy>
          )}
        >
          <Bar loading={fetching} value={getLimitBarValue(limit)}>
            {value}
          </Bar>
        </ConditionalWrap>
        <ControlledModal content={moreDetails}>
          <InlineIconButton
            disabled={disableMoreLimit || !limit}
            tippyContent="More details"
          >
            <InformationIcon />
          </InlineIconButton>
        </ControlledModal>
        {changeLimit && (
          <ControlledModal content={changeLimit}>
            <InlineIconButton
              disabled={disableChangeLimit}
              tippyContent="Change limit"
            >
              <EditIcon />
            </InlineIconButton>
          </ControlledModal>
        )}
        {removeLimit && (
          <ControlledModal content={removeLimit}>
            <InlineIconButton
              disabled={disableRemoveLimit || limit?.isDefault}
              tippyContent="Remove limit"
            >
              <TrashIcon />
            </InlineIconButton>
          </ControlledModal>
        )}
      </div>
      {limit?.pendingPeriodLimit && (
        <div className="text-xs leading-5 text-red-800">
          Limit will be updated to{' '}
          {formatMoney(limit?.pendingPeriodLimit?.value, currency)} on{' '}
          {formatDate(limit?.pendingPeriodLimit?.validFrom)}
          {cancelLimit && (
            <ControlledModal content={cancelLimit}>
              <InlineIconButton tippyContent="Cancel limit">
                <CloseIcon />
              </InlineIconButton>
            </ControlledModal>
          )}
        </div>
      )}
      {limit?.pendingPeriodLimitRemoval && (
        <div className="text-xs leading-5 text-red-800">
          Limit will be removed on{' '}
          {formatDate(limit.pendingPeriodLimitRemoval.validFrom)}
        </div>
      )}
    </>
  );
};
