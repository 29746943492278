import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import queryString from 'query-string';
import React, { FC } from 'react';

import { useGetChallengeDetailPageLink } from '@/bits/links/useLink';
import { Card, CardBody, CardOptions, CardOptionsButton } from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import { NextIcon, PreviousIcon, RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import { PlayerProgressStatus } from '@/globalTypes';
import { assert } from '@/utils/error';
import { PlayerChallengeSearchQuery } from './__generated__/usePlayerChallenge';
import usePlayerChallenge from './usePlayerChallenge';

export const Fragment = graphql`
  fragment SanityPlayerChallengeBlock on SanityPlayerChallengeBlock {
    title {
      ...SanityLocaleString
    }
  }
`;

const getRewardName = (
  row: NonNullable<
    NonNullable<
      PlayerChallengeSearchQuery['viewer']['playersChallenge']['edges']
    >[number]
  >['node'],
) => {
  return row.challengeRewards?.map((item) => item?.challengeRewardType.name);
};

const PlayerChallengeBlock: FC<{
  block: Queries.SanityPlayerChallengeBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const params = useParams();
  const getChallengeDetailLink = useGetChallengeDetailPageLink();

  assert(params.playerId, 'missing playerId');

  const {
    playerChallenge,
    fetching,
    nextPage,
    previousPage,
    refresh,
    orderBy,
    desc,
    setOrderBy,
    defaultFilter,
    updateFilter,
  } = usePlayerChallenge();

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'playerChallenge',
    data: playerChallenge,
    fetching,
    orderBy,
    setOrderBy,
    desc,
    defaultFilter,
    updateFilter,
    schema: (s) => [
      s.stringValue({
        field: 'challengeName',
        title: 'Challenge name',
        getValue: ({ row }) => row.challengeName,
        linkTo: ({ row }) =>
          getChallengeDetailLink(
            `${row.challengeUUID}?${queryString.stringify({
              tabIndex: 0,
            })}`,
          ) || '',
      }),
      s.stringValue({
        field: 'challengeId',
        title: 'Challenge Id',
        getValue: ({ row }) => row.challengeUUID,
      }),
      s.stringValue({
        field: 'playerProgress',
        title: 'Player Progress',
        getValue({ row }) {
          const { challengeCriteriaCompleted, challengeCriteriaTotal } = row;
          return `${challengeCriteriaCompleted} / ${challengeCriteriaTotal}`;
        },
      }),
      s.stringValue({
        field: 'rewardTypes',
        title: 'Reward Types',
        getValue: ({ row }) => getRewardName(row).join(', '),
      }),
      s.dateTimeValue({
        field: 'startDate',
        title: 'Start Date',
      }),
      s.dateTimeValue({
        field: 'endDate',
        title: 'End date',
      }),
      s.enumValue({
        field: 'status',
        title: 'Challenge Status',
        filterField: 'status',
        isMulti: true,
        e: PlayerProgressStatus,
      }),
      s.stringValue({
        field: 'timesCompleted',
        title: 'Completed (# of times)',
      }),
      s.stringValue({
        field: 'timesRestarted',
        title: 'Restarted (# of times)',
      }),
    ],
  });

  return (
    <Card
      size="lg"
      title={t(block.title)}
      showOptionsAtBottom
      options={
        <CardOptions>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
          <GlobalCardOptions />
          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <DataGrid />
      </CardBody>
    </Card>
  );
};

export default PlayerChallengeBlock;
