import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { gql, useQuery } from 'urql';

import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  Copy,
  Country,
  Date as DateComponent,
  GlobalPlayerBrandValues,
  Value,
} from '@/components';
import { GlobalPlayerSelfExclusionValues } from '@/components/GlobalPlayerSelfExclusionValues';
import { RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import { getGenericBrandsValues, getSelfExclusionBrandsValues } from '@/utils';
import { assert } from '@/utils/error';
import formatCountryCode from '@/utils/formatter/formatCountryCode';
import formatPhoneNumber from '@/utils/formatter/formatPhoneNumber';
import {
  GlobalPlayerQuickOverviewBoxQuery,
  GlobalPlayerQuickOverviewBoxQueryVariables,
} from './__generated__/component';

export const Fragment = graphql`
  fragment SanityGlobalPlayerQuickOverviewBlock on SanityGlobalPlayerQuickOverviewBlock {
    title {
      ...SanityLocaleString
    }
    phone {
      ...SanityLocaleString
    }
    email {
      ...SanityLocaleString
    }
    country {
      ...SanityLocaleString
    }
    accountStatus {
      ...SanityLocaleString
    }
    lastLogin {
      ...SanityLocaleString
    }
    selfExclusion {
      ...SanityLocaleString
    }
  }
`;

const QUERY = gql`
  query GlobalPlayerQuickOverviewBox($playerGlobalId: ID!) {
    playerGlobal(playerGlobalId: $playerGlobalId) {
      id
      playerGlobalUUID
      players {
        id
        rawPlayerId
        brand {
          id
          name
        }
        email
        phoneNumber
        countryCode
        accountStatus {
          status
        }
        sowBlockedStatus
        selfExclusionDetails {
          configuredAt
          exclusionEndsAt
        }
        loginInfo {
          lastLogin {
            loggedAt
            countryCode
          }
          registration {
            loggedAt
          }
        }
      }
    }
  }
`;

const GlobalPlayerQuickOverviewBlock: FC<{
  block: Queries.SanityGlobalPlayerQuickOverviewBlockFragment;
}> = ({ block }) => {
  const params = useParams();
  const { t } = useTranslate();

  assert(params.playerGlobalId, 'missing globalPlayerId');

  const [{ data, fetching }, refresh] = useQuery<
    GlobalPlayerQuickOverviewBoxQuery,
    GlobalPlayerQuickOverviewBoxQueryVariables
  >({
    query: QUERY,
    variables: {
      playerGlobalId: params.playerGlobalId,
    },
  });

  const firstPlayer = data?.playerGlobal.players[0];

  return (
    <Card
      size="lg"
      title={
        <>
          {t(block.title)}{' '}
          <Copy
            fetching={fetching}
            value={data?.playerGlobal.playerGlobalUUID}
          />
        </>
      }
      options={
        <CardOptions>
          <CardOptionsButton
            className="flex"
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        {data && (
          <div className="p-3">
            <Value
              fetching={fetching}
              title={t(block.phone)}
              value={formatPhoneNumber(firstPlayer?.phoneNumber, false)}
              suffix={
                <GlobalPlayerBrandValues
                  title={t(block.phone)}
                  values={getGenericBrandsValues(
                    data,
                    (entry) => entry.phoneNumber,
                  )}
                />
              }
            />
            <Value
              fetching={fetching}
              title={t(block.email)}
              value={firstPlayer?.email}
              suffix={
                <GlobalPlayerBrandValues
                  title={t(block.email)}
                  values={getGenericBrandsValues(data, (entry) => entry.email)}
                />
              }
            />
            <Value
              fetching={fetching}
              title={t(block.country)}
              value={firstPlayer?.countryCode}
              suffix={
                <GlobalPlayerBrandValues
                  title={t(block.country)}
                  values={getGenericBrandsValues(
                    data,
                    (entry) => entry.countryCode,
                  )}
                />
              }
            >
              <Country countryCode={firstPlayer?.countryCode} />
            </Value>
            <Value
              fetching={fetching}
              title={t(block.accountStatus)}
              value={firstPlayer?.accountStatus.status}
              suffix={
                <GlobalPlayerBrandValues
                  title={t(block.country)}
                  values={getGenericBrandsValues(
                    data,
                    (entry) =>
                      entry.accountStatus && entry.accountStatus.status,
                  )}
                />
              }
            />
            <Value fetching={fetching} title={t(block.lastLogin)}>
              {formatCountryCode(
                firstPlayer?.loginInfo?.lastLogin?.countryCode,
              )}{' '}
              <DateComponent
                copyable
                date={firstPlayer?.loginInfo?.lastLogin?.loggedAt}
              />
            </Value>
            <Value
              fetching={fetching}
              title={t(block.selfExclusion)}
              value={data?.playerGlobal.players.length}
              suffix={
                <GlobalPlayerSelfExclusionValues
                  title={t(block.selfExclusion)}
                  values={getSelfExclusionBrandsValues(data)}
                />
              }
            />
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default GlobalPlayerQuickOverviewBlock;
