import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import queryString from 'query-string';
import React, { FC } from 'react';

import { useGetRewardDetailPageLink } from '@/bits/links/useLink';
import {
  Card,
  CardBody,
  CardCloseButton,
  CardOptions,
  CardOptionsButton,
  ControlledModal,
  InlineIconButton,
} from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import {
  EditIcon,
  NextIcon,
  PlusIcon,
  PreviousIcon,
  RefreshIcon,
  TrashIcon,
} from '@/components/icons';
import { TranslateFn, useTranslate } from '@/contexts';
import { PlayerRewardCreateForm } from '@/forms';
import RewardRemoveForm from '@/forms/reward-remove-form/RewardRemoveForm';
import RewardResolveForm from '@/forms/reward-resolve-form/RewardResolveForm';
import { PlayerRewardStatus, RewardTypeEnum } from '@/globalTypes';
import formatMoney from '@/utils/formatter/formatMoney';
import { PlayerRewardQuery } from './__generated__/usePlayerRewards';
import usePlayerRewards from './usePlayerRewards';

export const Fragment = graphql`
  fragment SanityPlayerRewardsBlock on SanityPlayerRewardsBlock {
    title {
      ...SanityLocaleString
    }
    numberOfSpins {
      ...SanityLocaleString
    }
    reason {
      ...SanityLocaleString
    }
    actions {
      ...SanityLocaleString
    }
    removePlayerReward {
      ...SanityLocaleString
    }
    resolvePlayerReward {
      ...SanityLocaleString
    }
    noAction {
      ...SanityLocaleString
    }
  }
`;

type PlayerRewardType = NonNullable<
  NonNullable<PlayerRewardQuery['player']['playerRewards']['edges']>[number]
>['node'];

type RewardType = PlayerRewardType['reward']['rewardType'];

const isActiveReward = (status: string) => {
  if (
    status !== 'Removed' &&
    status !== 'FailedProcessing' &&
    status !== 'Credited' &&
    status !== 'Expired'
  ) {
    return true;
  }
  return false;
};

const getRewardAmount = (rewardType: RewardType, translationSpins: string) => {
  if (rewardType?.__typename === 'FreeSpinReward') {
    return `${translationSpins} ${rewardType.freeSpinsAmount}`;
  }
  if (rewardType?.__typename === 'MoneyDropReward') {
    return formatMoney(rewardType.moneyDropAmount, rewardType.currency);
  }
  if (rewardType?.__typename === 'BonusMoneyDropReward') {
    return formatMoney(rewardType.bonusMoneyDropAmount, rewardType.currency);
  }
  return undefined;
};

const getRewardAction = (
  row: PlayerRewardType,
  t: TranslateFn,
  rewardId: string,
  playerId: string,
  assignationId: string,
  block: Queries.SanityPlayerRewardsBlockFragment,
) => {
  if (isActiveReward(row.status)) {
    return (
      <div className="flex items-center">
        <ControlledModal
          content={
            <Card
              title={t(block.removePlayerReward)}
              size="lg"
              options={
                <CardOptions>
                  <CardCloseButton />
                </CardOptions>
              }
            >
              <RewardRemoveForm
                playerId={playerId}
                rewardId={rewardId}
                assignationId={assignationId}
              />
            </Card>
          }
        >
          <InlineIconButton>
            <TrashIcon />
          </InlineIconButton>
        </ControlledModal>
      </div>
    );
  }
  if (row.status === 'FailedProcessing') {
    return (
      <div className="flex items-center">
        <ControlledModal
          content={
            <Card
              title={t(block.resolvePlayerReward)}
              size="lg"
              options={
                <CardOptions>
                  <CardCloseButton />
                </CardOptions>
              }
            >
              <RewardResolveForm
                playerId={playerId}
                reason={row.reason}
                rewardId={rewardId}
                assignationId={row.assignationId}
              />
            </Card>
          }
        >
          <InlineIconButton>
            <EditIcon />
          </InlineIconButton>
        </ControlledModal>
      </div>
    );
  }
  return <>{t(block.noAction)}</>;
};

const PlayerRewardsBlock: FC<{
  block: Queries.SanityPlayerRewardsBlockFragment;
}> = ({ block }) => {
  const params = useParams();
  const { t } = useTranslate();

  const getRewardDetailLink = useGetRewardDetailPageLink();

  const {
    player,
    playerRewards,
    fetching,
    nextPage,
    previousPage,
    refresh,
    orderBy,
    desc,
    setOrderBy,
    defaultFilter,
    updateFilter,
  } = usePlayerRewards(params.playerId);

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'playerRewards',
    data: playerRewards,
    fetching,
    orderBy,
    setOrderBy,
    desc,
    defaultFilter,
    updateFilter,
    schema: (s) => [
      s.dateTimeValue({
        field: 'createdAt',
        title: 'Created Date',
        getValue: ({ row }) => row.reward?.createdAt,
      }),
      s.dateTimeValue({
        field: 'assignedAt',
        title: 'Assigned At',
        fromFilterField: 'assignedAtFrom',
        toFilterField: 'assignedAtTo',
      }),
      s.stringValue({
        field: 'name',
        title: 'Reward Name',
        getValue: ({ row }) => row.reward?.name,
        linkTo: ({ row }) =>
          getRewardDetailLink(
            `${row.reward?.id}?${queryString.stringify({
              tabIndex: 0,
            })}`,
          ) || '',
      }),
      s.stringValue({
        field: 'createdBy',
        title: 'Created By',
        getValue: ({ row }) =>
          row.reward?.createdBy?.firstName +
          ' ' +
          row.reward?.createdBy?.lastName,
      }),
      s.enumValue({
        field: 'rewardType',
        title: 'Type',
        e: RewardTypeEnum,
        getValue: ({ row }) => row.reward?.rewardType.name,
      }),
      s.stringValue({
        field: 'amount',
        title: 'Value/Amount',
        getValue: ({ row }) =>
          getRewardAmount(row?.reward?.rewardType, t(block.numberOfSpins)),
      }),
      s.dateTimeValue({
        field: 'startsAt',
        title: 'Start At',
        getValue: ({ row }) => row.reward?.startsAt,
      }),
      s.dateTimeValue({
        field: 'endsAt',
        title: 'Ends At',
        getValue: ({ row }) => row.reward?.endsAt,
      }),
      s.enumValue({
        field: 'status',
        title: 'Status',
        filterField: 'rewardStatus',
        e: PlayerRewardStatus,
      }),
      s.stringValue({
        field: 'reason',
        title: t(block.reason),
        getValue: ({ row }) =>
          row.status === 'FailedProcessing' ? '-' : row.reason,
      }),
      s.stringValue({
        field: 'assignationId',
        title: 'Assignation ID',
        filterField: 'assignationId',
      }),
      s.stringValue({
        field: '',
        title: t(block.actions),
        wrap: ({ row }) =>
          getRewardAction(
            row,
            t,
            row.reward.id,
            player?.uuid || '',
            row.assignationId || '',
            block,
          ),
      }),
    ],
  });

  return (
    <Card
      size="lg"
      title={t(block.title)}
      showOptionsAtBottom
      options={
        <CardOptions>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
          <GlobalCardOptions />
          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
          <ControlledModal
            content={
              player ? <PlayerRewardCreateForm playerId={player.id} /> : null
            }
          >
            <CardOptionsButton>
              <PlusIcon />
            </CardOptionsButton>
          </ControlledModal>
        </CardOptions>
      }
    >
      <CardBody>
        <DataGrid />
      </CardBody>
    </Card>
  );
};

export default PlayerRewardsBlock;
