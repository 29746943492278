import React, {
  Children,
  ComponentProps,
  FC,
  ReactElement,
  ReactEventHandler,
  ReactNode,
  Suspense,
  cloneElement,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import Draggable from 'react-draggable';
import ReactModal from 'react-modal';

import { useIsMounted } from '@/hooks';
import { DeepNonNullable } from '@/types';
import { InlineSpinner } from './InlineSpinner';

export const Modal: FC<ReactModal.Props> = ({
  className,
  overlayClassName,
  ...rest
}) => (
  <ReactModal
    className="block m-6"
    overlayClassName="fixed inset-0 bg-gray-700 dark:bg-gray-500 bg-opacity-70 dark:bg-opacity-70 overflow-y-auto z-20"
    {...rest}
  />
);

type ModalContextType = { close: null | (() => void) };

const ModalContext = createContext<ModalContextType>({
  close: null,
});

export const useModalContext = () => {
  const context = useContext(ModalContext);
  return context;
};

export const ControlledModal: FC<
  Omit<ComponentProps<typeof Modal>, 'isOpen'> & {
    content: ComponentProps<typeof LocalModal>['content'];
  }
> = ({ children, content }) => {
  const [isOpen, setIsOpen] = useState(false);
  const isMounted = useIsMounted();

  const ctx = useMemo(() => {
    return {
      close: () => setIsOpen(false),
    };
  }, [setIsOpen]);

  const child = Children.only(children) as ReactElement<{
    onClick?: ReactEventHandler;
  }>;

  const onClick: ReactEventHandler = useCallback(
    (event) => {
      if (content && isMounted) {
        setIsOpen(true);

        // Bypass onClick if it was present
        if (child && child.props && typeof child.props.onClick === 'function') {
          child.props.onClick(event);
        }
      }
    },
    [child, content, setIsOpen, isMounted],
  );

  const childNode = cloneElement(child, { onClick });

  return (
    <>
      <LocalModal isOpen={isOpen} ctx={ctx} content={content} />
      {childNode}
    </>
  );
};

export const LocalModal: FC<{
  isOpen: boolean;
  ctx: {
    close: () => void;
  };
  content: ReactNode | ((ctx: DeepNonNullable<ModalContextType>) => ReactNode);
}> = ({ isOpen, ctx, content }) => {
  const nodeRef = useRef<HTMLDivElement>(null!);

  return (
    <Modal isOpen={isOpen} onRequestClose={ctx.close}>
      <ModalContext.Provider value={ctx}>
        <Draggable handle=".drag_handle" nodeRef={nodeRef}>
          <div ref={nodeRef} className="flex w-full justify-center">
            <div className="w-full max-w-5xl">
              <Suspense fallback={<InlineSpinner />}>
                {typeof content === 'function' ? content(ctx) : content}
              </Suspense>
            </div>
          </div>
        </Draggable>
      </ModalContext.Provider>
    </Modal>
  );
};
