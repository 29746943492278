import {
  BooleanParam,
  DateTimeParam,
  NumberParam,
  StringParam,
  withDefault,
} from 'use-query-params';

import { AggregatedKYC6MatchesOrderBy } from '@/globalTypes';
import {
  AggregatedKYC6MatchesOrderParam,
  KYC6MatchTypesParam,
  KYC6ReviewStatusParam,
} from '@/utils/query/params';

export const queryParams = {
  after: StringParam,
  before: StringParam,
  desc: withDefault(BooleanParam, true),
  first: withDefault(NumberParam, 20),
  last: NumberParam,
  playerGlobalUUID: StringParam,
  kyc6MatchTypes: KYC6MatchTypesParam,
  lastSearchedAtFrom: DateTimeParam,
  lastSearchedAtTo: DateTimeParam,
  reviewStatus: KYC6ReviewStatusParam,
  orderBy: withDefault(
    AggregatedKYC6MatchesOrderParam,
    AggregatedKYC6MatchesOrderBy.LastSearchedAt,
  ),
};
