import { graphql } from 'gatsby';
import queryString from 'query-string';
import React, { FC } from 'react';

import { useGetRewardDetailPageLink } from '@/bits/links/useLink';
import { Card, CardBody, CardOptions, CardOptionsButton } from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import { NextIcon, PreviousIcon, RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import { usePlayer } from '@/hooks/usePlayer';
import formatMoney from '@/utils/formatter/formatMoney';
import usePlayerBonuses from './usePlayerBonuses';

export const Fragment = graphql`
  fragment SanityPlayerBonusesBlock on SanityPlayerBonusesBlock {
    title {
      ...SanityLocaleString
    }
    status {
      ...SanityLocaleString
    }
    campaignId {
      ...SanityLocaleString
    }
    createdAt {
      ...SanityLocaleString
    }
    bonusId {
      ...SanityLocaleString
    }
    assignationId {
      ...SanityLocaleString
    }
    rewardId {
      ...SanityLocaleString
    }
    createdBy {
      ...SanityLocaleString
    }
    amount {
      ...SanityLocaleString
    }
    amountForfeited {
      ...SanityLocaleString
    }
    amountDiscarded {
      ...SanityLocaleString
    }
    amountConverted {
      ...SanityLocaleString
    }
    forfeitCause {
      ...SanityLocaleString
    }
    closingBalance {
      ...SanityLocaleString
    }
    maxPayout {
      ...SanityLocaleString
    }
    wageringRequirement {
      ...SanityLocaleString
    }
    wageringRequirementFactor {
      ...SanityLocaleString
    }
    processedAt {
      ...SanityLocaleString
    }
    expiresAt {
      ...SanityLocaleString
    }
  }
`;

const PlayerBonusesBlock: FC<{
  block: Queries.SanityPlayerBonusesBlockFragment;
}> = ({ block }) => {
  const player = usePlayer();
  const { t } = useTranslate();
  const getRewardDetailLink = useGetRewardDetailPageLink();

  const {
    playerBonuses,
    fetching,
    nextPage,
    previousPage,
    refresh,
    orderBy,
    desc,
    setOrderBy,
    defaultFilter,
    updateFilter,
  } = usePlayerBonuses(player.uuid);

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'PlayerBonuses',
    data: playerBonuses,
    fetching,
    orderBy,
    setOrderBy,
    desc,
    defaultFilter,
    updateFilter,
    schema: (s) => [
      s.stringValue({
        field: 'status',
        title: t(block.status),
        getValue: ({ row }) => row.status,
      }),
      s.stringValue({
        field: 'campaignId',
        title: t(block.campaignId),
        getValue: ({ row }) => row.campaignId,
      }),
      s.dateTimeValue({
        field: 'createdAt',
        title: t(block.createdAt),
        getValue: ({ row }) => row.createdAt,
      }),
      s.stringValue({
        field: 'bonusId',
        title: t(block.bonusId),
        getValue: ({ row }) => row.bonusId,
      }),
      s.stringValue({
        field: 'assignationId',
        title: t(block.assignationId),
      }),
      s.stringValue({
        field: 'rewardId',
        title: t(block.rewardId),
        linkTo: ({ row }) =>
          getRewardDetailLink(
            `${row.rewardId}?${queryString.stringify({
              tabIndex: 0,
            })}`,
          ) || '',
      }),
      s.initiatorValue({
        field: 'createdBy',
        title: t(block.createdBy),
        getValue: ({ row }) => row.createdBy,
      }),
      s.stringValue({
        field: 'amount',
        title: t(block.amount),
        getValue: ({ row }) => formatMoney(row.amount, row.currency),
      }),
      s.stringValue({
        field: 'amountForfeited',
        title: t(block.amountForfeited),
        getValue: ({ row }) => formatMoney(row.amountForfeited, row.currency),
      }),
      s.stringValue({
        field: 'amountDiscarded',
        title: t(block.amountDiscarded),
        getValue: ({ row }) => formatMoney(row.amountDiscarded, row.currency),
      }),
      s.stringValue({
        field: 'amountConverted',
        title: t(block.amountConverted),
        getValue: ({ row }) => formatMoney(row.amountConverted, row.currency),
      }),
      s.stringValue({
        field: 'forfeitCause',
        title: t(block.forfeitCause),
        getValue: ({ row }) => row.forfeitCause,
      }),
      s.stringValue({
        field: 'closingBalance',
        title: t(block.closingBalance),
        getValue: ({ row }) => formatMoney(row.closingBalance, row.currency),
      }),
      s.stringValue({
        field: 'maxPayout',
        title: t(block.maxPayout),
        getValue: ({ row }) => formatMoney(row.closingBalance, row.currency),
      }),
      s.stringValue({
        field: 'wageringRequirement',
        title: t(block.wageringRequirement),
        getValue: ({ row }) =>
          formatMoney(row.wageringRequirement, row.currency),
      }),
      s.stringValue({
        field: 'wageringRequirementFactor',
        title: t(block.wageringRequirementFactor),
        getValue: ({ row }) => 'x' + row.wageringRequirementFactor,
      }),
      s.dateTimeValue({
        field: 'processedAt',
        title: t(block.processedAt),
        getValue: ({ row }) => row.processedAt,
      }),
      s.dateTimeValue({
        field: 'expiresAt',
        title: t(block.expiresAt),
        getValue: ({ row }) => row.expiresAt,
      }),
    ],
  });

  return (
    <Card
      size="lg"
      title={t(block.title)}
      showOptionsAtBottom
      options={
        <CardOptions>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
          <GlobalCardOptions />
          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <DataGrid />
      </CardBody>
    </Card>
  );
};

export default PlayerBonusesBlock;
