import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { useMutation } from 'urql';

import { ScreeningType } from '@/blocks/player-screening-statuses-block/components';
import {
  Card,
  CardCloseButton,
  CardOptions,
  ErrorMessage,
  Form,
  SubmitButton,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import {
  RemoveFraudStatusOverwriteMutation,
  RemovePepStatusOverwriteMutation,
  RemoveSanctionedStatusOverwriteMutation,
} from './__generated__/PlayerRemoveStatusOverwriteForm';

const query = graphql`
  query SanityPlayerRemoveStatusOverwriteForm {
    sanityPlayerRemoveStatusOverwriteForm {
      title {
        ...SanityLocaleString
      }
    }
  }
`;

const removePepStatusOverwriteMutation = gql`
  mutation RemovePepStatusOverwrite($playerId: ID!) {
    removePepStatusOverwrite(playerId: $playerId) {
      pep {
        overwrite {
          details
          decision
          madeBy {
            __typename
          }
          at
        }
      }
    }
  }
`;

const removeSanctionedStatusOverwriteMutation = gql`
  mutation RemoveSanctionedStatusOverwrite($playerId: ID!) {
    removeSanctionedStatusOverwrite(playerId: $playerId) {
      sanctioned {
        overwrite {
          details
          decision
          madeBy {
            __typename
          }
          at
        }
      }
    }
  }
`;

const removeFraudStatusOverwriteMutation = gql`
  mutation RemoveFraudStatusOverwrite($playerId: ID!) {
    removeFraudStatusOverwrite(playerId: $playerId) {
      fraud {
        overwrite {
          details
          decision
          madeBy {
            __typename
          }
          at
        }
      }
    }
  }
`;

type Props = {
  playerId: string;
  screeningType: ScreeningType;
  fallback: () => void;
};

const PlayerRemoveStatusOverwriteForm: FC<Props> = ({
  playerId,
  screeningType,
  fallback,
}) => {
  const { close } = useModalContext();
  const { t } = useTranslate();

  const staticData =
    useStaticQuery<Queries.SanityPlayerRemoveStatusOverwriteFormQuery>(query);

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();

  const form = staticData.sanityPlayerRemoveStatusOverwriteForm;

  const [removePepStatusOverwriteState, removePepStatusOverwrite] =
    useMutation<RemovePepStatusOverwriteMutation>(
      removePepStatusOverwriteMutation,
    );

  const [
    removeSanctionedStatusOverwriteState,
    removeSanctionedStatusOverwrite,
  ] = useMutation<RemoveSanctionedStatusOverwriteMutation>(
    removeSanctionedStatusOverwriteMutation,
  );

  const [removeFraudStatusOverwriteState, removeFraudStatusOverwrite] =
    useMutation<RemoveFraudStatusOverwriteMutation>(
      removeFraudStatusOverwriteMutation,
    );

  const defaultValues = {};

  const onSubmit = () => {
    if (screeningType === 'sanctioned') {
      return removeSanctionedStatusOverwrite({
        playerId,
      }).then((res) => {
        if (res.error?.message && isMounted) {
          setErrorMessage(res.error.message);
        } else if (close) {
          fallback();
          close();
        }
      });
    }

    if (screeningType === 'fraud') {
      return removeFraudStatusOverwrite({
        playerId,
      }).then((res) => {
        if (res.error?.message && isMounted) {
          setErrorMessage(res.error.message);
        } else if (close) {
          fallback();
          close();
        }
      });
    }

    return removePepStatusOverwrite({
      playerId,
    }).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        fallback();
        close();
      }
    });
  };

  if (!form) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        <Form
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          className="grid grid-cols-2 gap-6"
        >
          <ErrorMessage message={errorMessage} />
          <SubmitButton
            value={'Remove'}
            disabled={
              removePepStatusOverwriteState.fetching ||
              removeSanctionedStatusOverwriteState.fetching ||
              removeFraudStatusOverwriteState.fetching
            }
          />
        </Form>
      </div>
    </Card>
  );
};

export default PlayerRemoveStatusOverwriteForm;
