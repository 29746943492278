import classNames from 'classnames';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { InlineIconButton } from '@/components';
import { ClipboardCopyIcon } from '@/components/icons';
import { Nullable } from '@/types';
import { InlineSpinner } from './InlineSpinner';

type Value = string | number;

export const Copy: FC<{
  value: Nullable<Value>;
  fetching?: boolean;
  children?: ReactNode;
}> = ({ value, fetching, children }) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      const id = setTimeout(() => setCopied(false), 1000);

      return () => clearTimeout(id);
    }
    return void 0;
  });

  if (fetching) {
    return <InlineSpinner />;
  }

  if (!value) {
    return <span>-</span>;
  }

  return (
    <>
      <span>{children || value}</span>
      <CopyToClipboard text={`${value}`} onCopy={() => setCopied(true)}>
        <InlineIconButton className="relative">
          <span
            className={classNames('absolute w-full h-full', {
              'bg-green-300 bg-opacity-100 transition-all animate-ping rounded-full':
                copied,
            })}
          ></span>
          <ClipboardCopyIcon />
        </InlineIconButton>
      </CopyToClipboard>
    </>
  );
};
