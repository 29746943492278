import { useParams } from '@reach/router';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { feedback } from 'react-feedbacker';
import { MdOutlineErrorOutline } from 'react-icons/md';
import { useQuery } from 'urql';

import { useFeedbackMessages } from '@/bits';
import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  ControlledModal,
  Copy,
  Country,
  Date as DateComponent,
  InlineIconButton,
  OptionsMenu,
  Value,
  renderOptionalValue,
} from '@/components';
import { AccountStatus } from '@/components/AccountStatus';
import { renderBooleanValue } from '@/components/DataGrid/values';
import { EditIcon, RefreshIcon } from '@/components/icons';
import { WithdrawalsStatus } from '@/components/WithdrawalsStatus';
import { useTranslate } from '@/contexts';
import {
  PlayerCountryForm,
  PlayerEmailForm,
  PlayerPhoneNumberForm,
  PlayerSetTestAccountForm,
} from '@/forms';
import { AccountStatusEnum, CloseAccountCauseV4 } from '@/globalTypes';
import { getRgRiskLevelColor } from '@/utils';
import formatCountryCode from '@/utils/formatter/formatCountryCode';
import formatPhoneNumber from '@/utils/formatter/formatPhoneNumber';
import { splitStringIntoTwoWords } from '@/utils/split-string-in-two-words';
import {
  PlayerInfoBoxQuery,
  PlayerInfoBoxQueryVariables,
  ViewerInfoBoxQuery,
  ViewerInfoBoxQueryVariables,
} from './__generated__/component';
import { PlayerSelfExclusionDetails } from './PlayerSelfExclusionDetails';
import useResendVerificationEmail from './useResendVerificationEmail';

export const Fragment = graphql`
  fragment SanityPlayerInfoBlock on SanityPlayerInfoBlock {
    title {
      ...SanityLocaleString
    }
    phoneNumber {
      ...SanityLocaleString
    }
    email {
      ...SanityLocaleString
    }
    edit {
      ...SanityLocaleString
    }
    resendVerificationEmail {
      ...SanityLocaleString
    }
    couldNotResendVerificationEmail {
      ...SanityLocaleString
    }
    country {
      ...SanityLocaleString
    }
    language {
      ...SanityLocaleString
    }
    accountStatus {
      ...SanityLocaleString
    }
    withdrawalsStatus {
      ...SanityLocaleString
    }
    withdrawalsStatusBlocked {
      ...SanityLocaleString
    }
    withdrawalsStatusNotBlocked {
      ...SanityLocaleString
    }
    accountStatusOpen {
      ...SanityLocaleString
    }
    accountStatusClosed {
      ...SanityLocaleString
    }
    accountWillReopen {
      ...SanityLocaleString
    }
    changeAccountStatus {
      ...SanityLocaleString
    }
    sowBlockedStatus {
      ...SanityLocaleString
    }
    sowNotBlocked {
      ...SanityLocaleString
    }
    sowBlocked {
      ...SanityLocaleString
    }
    selfExclusion {
      ...SanityLocaleString
    }
    excludedUntil {
      ...SanityLocaleString
    }
    willBeCancelledAt {
      ...SanityLocaleString
    }
    lastLogin {
      ...SanityLocaleString
    }
    pendingEnding {
      ...SanityLocaleString
    }
    indefinite {
      ...SanityLocaleString
    }
    hampiRestrictions {
      ...SanityLocaleString
    }
  }
`;

// TODO: This query is a temporary solution until we have this data inside the player
const queryViewer = gql`
  query ViewerInfoBox(
    $firstName: String!
    $lastName: String!
    $birthDate: LocalDate!
  ) {
    viewer {
      id
      hampiCheckByNamePlusDob(
        firstName: $firstName
        lastName: $lastName
        birthDate: $birthDate
      ) {
        restrictions
      }
    }
  }
`;

const query = gql`
  query PlayerInfoBox($playerId: ID!) {
    player(playerId: $playerId) {
      id
      rawPlayerId
      email
      isEmailVerified
      phoneNumber
      isPhoneNumberVerified
      isTestAccount
      countryCode
      language
      firstName
      lastName
      birthDate
      accountStatus {
        status
        cause
      }
      areWithdrawalsBlocked
      sowBlockedStatus
      shouldReopenAt
      selfExclusionDetails {
        id
        exclusionEndsAt
        willBeCancelledAt
        pending {
          exclusionEndsAt
          configuredAt
          activeFrom
        }
      }
      loginInfo {
        lastLogin {
          loggedAt
          countryCode
        }
      }
      rgCraCustomerRiskAssessment {
        riskLevel
      }
    }
  }
`;

const PlayerInfoBlock: FC<{
  block: Queries.SanityPlayerInfoBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const { playerId } = useParams();
  const feedbackMessages = useFeedbackMessages();
  const [, resendVerificationEmail] = useResendVerificationEmail();
  const isEstonia = process.env.GATSBY_LICENSE === 'EST';
  const isMGA = process.env.GATSBY_LICENSE === 'MGA';

  const [{ data, fetching }, refresh] = useQuery<
    PlayerInfoBoxQuery,
    PlayerInfoBoxQueryVariables
  >({
    query,
    variables: {
      playerId,
    },
  });

  const [{ data: dataViewer, fetching: fetchingViewer }] = useQuery<
    ViewerInfoBoxQuery,
    ViewerInfoBoxQueryVariables
  >({
    query: queryViewer,
    variables: {
      firstName: data?.player.firstName?.trim() ?? '',
      lastName: data?.player.lastName?.trim() ?? '',
      birthDate: data?.player.birthDate ?? '',
    },
    pause: !data || !isEstonia,
  });

  const sowBlockedStatuses: { [key: string]: string } = {
    NotBlocked: t(block.sowNotBlocked),
    SowBlock: t(block.sowBlocked),
  };

  return (
    <Card
      size="lg"
      title={
        <>
          {t(block?.title)}{' '}
          <Copy fetching={fetching} value={data?.player.rawPlayerId} />
        </>
      }
      options={
        <CardOptions>
          <CardOptionsButton
            className="flex"
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
          <Value
            fetching={fetching}
            title={t(block.phoneNumber)}
            value={formatPhoneNumber(data?.player.phoneNumber, false)}
            suffix={
              <ControlledModal
                content={
                  data?.player.id ? (
                    <PlayerPhoneNumberForm playerId={data?.player.id} />
                  ) : null
                }
              >
                <InlineIconButton>
                  <EditIcon />
                </InlineIconButton>
              </ControlledModal>
            }
          >
            {`${renderOptionalValue(
              formatPhoneNumber(data?.player.phoneNumber, false),
            )} ${renderBooleanValue(!!data?.player.isPhoneNumberVerified)} `}
          </Value>
          <Value
            fetching={fetching}
            title={t(block.email)}
            value={data?.player.email}
            suffix={
              <OptionsMenu
                items={[
                  {
                    content: t(block.edit),
                    modalContent: data?.player.id && (
                      <PlayerEmailForm playerId={data.player.id} />
                    ),
                    icon: <EditIcon />,
                  },
                  {
                    content: t(block.resendVerificationEmail),
                    icon: <RefreshIcon />,
                    isHidden:
                      !data?.player.email || data.player.isEmailVerified,
                    shouldConfirm: true,
                    onClick: () => {
                      if (!data?.player.id) {
                        return;
                      }
                      resendVerificationEmail({
                        playerId: data.player.id,
                      }).then((res) => {
                        if (res.data?.resendVerificationEmail.playerId) {
                          return feedback.success(t(feedbackMessages.success));
                        }
                        feedback.error(
                          t(block.couldNotResendVerificationEmail),
                        );
                      });
                    },
                  },
                ]}
              />
            }
          >
            {`${renderOptionalValue(data?.player.email)} ${renderBooleanValue(
              !!data?.player.isEmailVerified,
            )} `}
          </Value>
          <Value fetching={fetching} title={t(block.country)}>
            <Country countryCode={data?.player.countryCode} />
            <ControlledModal
              content={
                data?.player.id ? (
                  <PlayerCountryForm playerId={data.player.id} />
                ) : null
              }
            >
              <InlineIconButton>
                <EditIcon />
              </InlineIconButton>
            </ControlledModal>
          </Value>
          <Value fetching={fetching} title={t(block.accountStatus)}>
            <AccountStatus
              block={block}
              status={data?.player.accountStatus.status as AccountStatusEnum}
              cause={data?.player.accountStatus.cause as CloseAccountCauseV4}
              playerId={playerId}
            />
            {data?.player.shouldReopenAt && (
              <>
                {t(block.accountWillReopen)}
                <DateComponent copyable date={data?.player.shouldReopenAt} />
              </>
            )}
          </Value>
          <Value
            fetching={fetching}
            title={t(block.sowBlockedStatus)}
            value={
              data?.player.sowBlockedStatus &&
              sowBlockedStatuses[data?.player.sowBlockedStatus]
            }
          />
          <Value
            fetching={fetching}
            title={t(block.withdrawalsStatus)}
            value={
              data?.player.areWithdrawalsBlocked
                ? t(block.withdrawalsStatusBlocked)
                : t(block.withdrawalsStatusNotBlocked)
            }
          >
            <WithdrawalsStatus
              block={block}
              isBlocked={data?.player.areWithdrawalsBlocked ?? false}
              playerId={playerId}
            />
          </Value>
          <Value fetching={fetching} title={t(block.selfExclusion)}>
            <PlayerSelfExclusionDetails block={block} data={data} />
          </Value>
          <Value fetching={fetching} title={t(block.lastLogin)}>
            {formatCountryCode(data?.player.loginInfo?.lastLogin?.countryCode)}{' '}
            <DateComponent
              copyable
              date={data?.player.loginInfo?.lastLogin?.loggedAt}
            />
          </Value>
          <Value
            fetching={fetching}
            title={t(block.language)}
            value={data?.player.language}
          />
          {isEstonia && (
            <Value
              fetching={fetchingViewer}
              title={t(block.hampiRestrictions)}
              value={dataViewer?.viewer.hampiCheckByNamePlusDob?.restrictions.join(
                ', ',
              )}
            />
          )}
          {isMGA && (
            <Value fetching={fetching} title={'RG CRA Risk'}>
              <div className="flex gap-1 items-center">
                <MdOutlineErrorOutline
                  className={classNames(
                    getRgRiskLevelColor(
                      data?.player.rgCraCustomerRiskAssessment?.riskLevel,
                    ),
                  )}
                />
                <span>
                  {splitStringIntoTwoWords(
                    data?.player.rgCraCustomerRiskAssessment?.riskLevel,
                  )}
                </span>
              </div>
            </Value>
          )}
          <Value
            fetching={fetching}
            title="Is test account"
            value={data?.player.isTestAccount ? 'Yes' : 'No'}
            suffix={
              !data?.player.isTestAccount && (
                <ControlledModal
                  content={
                    data?.player.id ? (
                      <PlayerSetTestAccountForm playerId={data?.player.id} />
                    ) : null
                  }
                >
                  <InlineIconButton>
                    <EditIcon />
                  </InlineIconButton>
                </ControlledModal>
              )
            }
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerInfoBlock;
