import React from 'react';

import { DetailList } from '@/components';
import { ChallengeDetailQuery } from './__generated__/useChallengeDetail';

type RewardType = NonNullable<
  NonNullable<ChallengeDetailQuery['viewer']['challenges']['edges']>[number]
>['node']['challengeRewards'][number];

const getGameFromDescriptor = (
  challengeRewardType: RewardType['challengeRewardType'],
) => {
  const gameDescriptor =
    'gameDescriptor' in challengeRewardType
      ? challengeRewardType.gameDescriptor
      : undefined;

  const typename = gameDescriptor?.__typename;

  return typename === 'GameDescriptorV2' ? gameDescriptor?.json : undefined;
};

export const renderRewardDetails = (reward: RewardType) => {
  const { challengeRewardType } = reward;
  const type = challengeRewardType.__typename;
  const game = getGameFromDescriptor(challengeRewardType);

  if (type === 'FreeSpinChallengeReward') {
    return (
      <DetailList
        items={[
          {
            label: 'Name',
            value: challengeRewardType.name,
          },
          {
            label: 'Bet Level',
            value: challengeRewardType.betLevel,
          },
          {
            label: 'Free Spins Amount',
            value: challengeRewardType.freeSpinsAmount,
          },
          {
            label: 'Value (cents)',
            value: challengeRewardType.value,
          },
          {
            label: 'Currency',
            value: challengeRewardType.currency,
          },
          {
            label: 'Game',
            value: `${game.gameProvider.name} - ${game.name} (${game.gameId})`,
          },
        ]}
      />
    );
  }

  if (type === 'MoneyDropChallengeReward') {
    return (
      <DetailList
        items={[
          { label: 'Name', value: challengeRewardType.name },
          {
            label: 'Money Drop Amount',
            value: challengeRewardType.moneyDropAmount,
          },
          { label: 'Currency', value: challengeRewardType.currency },
        ]}
      />
    );
  }

  if (type === 'BonusMoneyDropChallengeReward') {
    return (
      <DetailList
        items={[
          { label: 'Name', value: challengeRewardType.name },
          {
            label: 'Bonus Money Drop Amount',
            value: challengeRewardType.bonusMoneyDropAmount,
          },
          { label: 'Currency', value: challengeRewardType.currency },
          { label: 'Max Payout', value: challengeRewardType.maxPayout },
          {
            label: 'Wagering Requirement',
            value: challengeRewardType.wageringRequirementFactor,
          },
        ]}
      />
    );
  }

  if (type === 'DynamicBonusMoneyDropChallengeReward') {
    return (
      <DetailList
        items={[
          { label: 'Name', value: challengeRewardType.name },
          {
            label: 'Max Amount',
            value: challengeRewardType.maxAmount,
          },
          { label: 'Currency', value: challengeRewardType.currency },
          {
            label: 'Max Payout',
            value: challengeRewardType.maxPayout,
          },
          {
            label: 'Multiplier',
            value: challengeRewardType.multiplier,
          },
          {
            label: 'Wagering Requirement Factor',
            value: challengeRewardType.wageringRequirementFactor,
          },
        ]}
      />
    );
  }

  if (type === 'DynamicFreeSpinChallengeReward') {
    return (
      <DetailList
        items={[
          { label: 'Name', value: challengeRewardType.name },
          { label: 'Bet Level', value: challengeRewardType.betLevel },
          {
            label: 'Max Free Spins Amount',
            value: challengeRewardType.maxFreeSpinsAmount,
          },
          { label: 'Value (cents)', value: challengeRewardType.value },
          { label: 'Currency', value: challengeRewardType.currency },
          { label: 'Multiplier', value: challengeRewardType.multiplier },
          {
            label: 'Game',
            value: `${game.gameProvider.name} - ${game.name} (${game.gameId})`,
          },
        ]}
      />
    );
  }

  if (type === 'DynamicMoneyDropChallengeReward') {
    return (
      <DetailList
        items={[
          { label: 'Name', value: challengeRewardType.name },
          {
            label: 'Max Amount',
            value: challengeRewardType.maxAmount,
          },
          { label: 'Currency', value: challengeRewardType.currency },
          {
            label: 'Multiplier',
            value: challengeRewardType.multiplier,
          },
        ]}
      />
    );
  }
};
