import { Link } from 'gatsby';
import React, { FC } from 'react';

import { useGetPlayerOverviewPageLink } from '@/bits/links/useLink';
import {
  Card,
  CardCloseButton,
  CardOptions,
  ControlledModal,
  InlineIconButton,
  Table,
} from '@/components';
import { Nullable } from '@/types';
import { RenderBrandValueType, someItemIsDifferent } from '@/utils';
import formatDate, { isValidDate } from '@/utils/formatter/formatDate';
import { EyeIcon } from './icons';

const formatBrandValue = (brandValue: string | number) =>
  isValidDate(brandValue) ? formatDate(brandValue) : brandValue;

export const GlobalPlayerBrandValues: FC<{
  title: string;
  values: Nullable<RenderBrandValueType[]>;
}> = ({ title, values }) => {
  const brandValues = values?.map((a) => a.brandValue);

  const brandValuesAreDifferent = brandValues
    ? someItemIsDifferent(brandValues)
    : false;

  const getPlayerOverviewLink = useGetPlayerOverviewPageLink();

  return !brandValuesAreDifferent ? (
    <ControlledModal
      content={
        <Card
          title={title}
          size="lg"
          options={
            <CardOptions>
              <CardCloseButton />
            </CardOptions>
          }
        >
          <div className="p-3">
            {values && (
              <Table
                headings={['Brand', 'Value', 'Status', 'Player']}
                rows={values.map((row, i) => {
                  return {
                    key: `brand-values-${i}`,
                    data: [
                      <span>{row.brand}</span>,
                      <span>{formatBrandValue(row.brandValue)}</span>,
                      <span>{row.status}</span>,
                      <Link to={getPlayerOverviewLink(row.playerId) ?? ''}>
                        {row.rawPlayerId}
                      </Link>,
                    ],
                  };
                })}
              />
            )}
          </div>
        </Card>
      }
    >
      <InlineIconButton>
        <EyeIcon className="text-red-600" />
      </InlineIconButton>
    </ControlledModal>
  ) : null;
};
