import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { useMutation } from 'urql';

import {
  Card,
  CardBody,
  CardCloseButton,
  CardOptions,
  CheckboxField,
  ErrorMessage,
  Form,
  SubmitButton,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { useIsMounted } from '@/hooks';
import { Nullable, PlayerId } from '@/types';
import {
  PlayerGlobalUpdateGamanzaStatusMutation,
  PlayerGlobalUpdateGamanzaStatusMutationVariables,
} from './__generated__/PlayerGlobalUpdateGamanzaStatusForm';

const query = graphql`
  query SanityPlayerGlobalUpdateGamanzaStatusForm {
    sanityPlayerGlobalUpdateGamanzaStatusForm {
      title {
        ...SanityLocaleString
      }
    }
  }
`;

const updatePlayerGlobalGamanzaStatusMutation = gql`
  mutation PlayerGlobalUpdateGamanzaStatus(
    $playerGlobalId: ID!
    $blockPlayer: Boolean!
  ) {
    updateGamanzaBlockStatusByGlobalPlayerId: updateGamanzaBlockStatusByGlobalPlayerIdV2(
      playerGlobalId: $playerGlobalId
      blockPlayer: $blockPlayer
    ) {
      id
    }
  }
`;

const PlayerGlobalUpdateGamanzaStatusForm: FC<{
  playerGlobalId: PlayerId;
  blockPlayer: boolean;
}> = ({ playerGlobalId, blockPlayer }) => {
  const staticData =
    useStaticQuery<Queries.SanityPlayerGlobalUpdateGamanzaStatusFormQuery>(
      query,
    );

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();
  const form = staticData.sanityPlayerGlobalUpdateGamanzaStatusForm;
  const { close } = useModalContext();
  const { t } = useTranslate();

  const [updateGamanzaBlockStatusState, updateGamanzaBlockStatus] = useMutation<
    PlayerGlobalUpdateGamanzaStatusMutation,
    PlayerGlobalUpdateGamanzaStatusMutationVariables
  >(updatePlayerGlobalGamanzaStatusMutation);

  const defaultValues = {
    blockPlayer,
  };

  const onSubmit = ({ blockPlayer }: typeof defaultValues) => {
    updateGamanzaBlockStatus({
      playerGlobalId,
      blockPlayer,
    }).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        close();
      }
    });
  };

  if (!form) {
    return null;
  }

  return (
    <Card
      size="md"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3">
          <Form
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            className="grid gap-6"
          >
            <CheckboxField
              title="Is Global Player blocked in Gamanza?"
              name="blockPlayer"
              id="PlayerGlobalUpdateGamanzaStatusForm_blockPlayer"
              className="col-span-full"
              defaultChecked={blockPlayer}
            />
            <ErrorMessage message={errorMessage} />
            <SubmitButton
              value="Update"
              disabled={updateGamanzaBlockStatusState.fetching}
            />
          </Form>
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerGlobalUpdateGamanzaStatusForm;
