import React from 'react';

import { Card, CardCloseButton, CardOptions, DetailList } from '@/components';
import { getInitiator } from '@/utils';
import formatDate from '@/utils/formatter/formatDate';
import { GlobalPlayerGamanzaOverviewBoxQuery } from './__generated__/component';

const GamanzaGlobalPlayerStatusDetails = ({
  isBlocked,
  updatedAt,
  updatedBy,
}: GlobalPlayerGamanzaOverviewBoxQuery['playerGlobal']['getGamanzaGlobalPlayerBlockStatus']) => {
  const agentFullName = getInitiator(updatedBy);

  const items = [
    {
      label: 'Is Blocked',
      value: isBlocked ? 'Yes' : 'No',
    },
    {
      label: 'Updated At',
      value: formatDate(updatedAt) || '-',
    },
    {
      label: 'Updated By',
      value: agentFullName,
    },
  ];

  return (
    <Card
      size="lg"
      title={'Gamanza Global Player Block Status Details'}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <DetailList items={items} />
    </Card>
  );
};

export default GamanzaGlobalPlayerStatusDetails;
