type PlayerSegment =
  | 'Tier1'
  | 'Tier2'
  | 'Tier3'
  | 'Tier4'
  | 'X'
  | 'PVIP'
  | 'VIP1'
  | 'VIP2'
  | 'VIP3';

export type CalculateRewardCondition = {
  segment: PlayerSegment;
  minLoss: number;
  maxLoss: number;
  rewardAmount: number | null;
  wagering: number | null;
  maxWin: number | null;
  nrOfSpins: number | null;
  spinsValue: number | null;
};

type CalculateRewardResult = {
  rewardAmount: number | null;
  wagering: number | null;
  maxWin: number | null;
  nrOfSpins: number | null;
  spinsValue: number | null;
};

export const rewardConditions: CalculateRewardCondition[] = [
  {
    segment: 'Tier1',
    minLoss: 0,
    maxLoss: Infinity,
    rewardAmount: null,
    wagering: null,
    maxWin: null,
    nrOfSpins: null,
    spinsValue: null,
  },
  {
    segment: 'Tier2',
    minLoss: 100,
    maxLoss: Infinity,
    rewardAmount: 2,
    wagering: 25,
    maxWin: 10,
    nrOfSpins: 20,
    spinsValue: 10,
  },
  {
    segment: 'Tier3',
    minLoss: 100,
    maxLoss: 349,
    rewardAmount: 1,
    wagering: 25,
    maxWin: 10,
    nrOfSpins: 10,
    spinsValue: 10,
  },
  {
    segment: 'Tier3',
    minLoss: 350,
    maxLoss: Infinity,
    rewardAmount: 5,
    wagering: 25,
    maxWin: 50,
    nrOfSpins: 50,
    spinsValue: 10,
  },
  {
    segment: 'Tier4',
    minLoss: 100,
    maxLoss: 349,
    rewardAmount: 2,
    wagering: 25,
    maxWin: 20,
    nrOfSpins: 20,
    spinsValue: 20,
  },
  {
    segment: 'Tier4',
    minLoss: 350,
    maxLoss: 749,
    rewardAmount: 10,
    wagering: 25,
    maxWin: 100,
    nrOfSpins: 100,
    spinsValue: 10,
  },
  {
    segment: 'Tier4',
    minLoss: 750,
    maxLoss: Infinity,
    rewardAmount: 25,
    wagering: 35,
    maxWin: 250,
    nrOfSpins: 250,
    spinsValue: 10,
  },
  {
    segment: 'X',
    minLoss: 100,
    maxLoss: 349,
    rewardAmount: 2.5,
    wagering: 25,
    maxWin: 25,
    nrOfSpins: 25,
    spinsValue: 20,
  },
  {
    segment: 'X',
    minLoss: 350,
    maxLoss: 749,
    rewardAmount: 10,
    wagering: 25,
    maxWin: 100,
    nrOfSpins: 100,
    spinsValue: 10,
  },
  {
    segment: 'X',
    minLoss: 750,
    maxLoss: 999,
    rewardAmount: 25,
    wagering: 35,
    maxWin: 250,
    nrOfSpins: 250,
    spinsValue: 10,
  },
  {
    segment: 'X',
    minLoss: 1000,
    maxLoss: Infinity,
    rewardAmount: 50,
    wagering: 40,
    maxWin: 500,
    nrOfSpins: 250,
    spinsValue: 20,
  },
  {
    segment: 'PVIP',
    minLoss: 100,
    maxLoss: 349,
    rewardAmount: 2.5,
    wagering: 25,
    maxWin: 25,
    nrOfSpins: 25,
    spinsValue: 20,
  },
  {
    segment: 'PVIP',
    minLoss: 350,
    maxLoss: 749,
    rewardAmount: 10,
    wagering: 25,
    maxWin: 100,
    nrOfSpins: 100,
    spinsValue: 10,
  },
  {
    segment: 'PVIP',
    minLoss: 750,
    maxLoss: 999,
    rewardAmount: 25,
    wagering: 35,
    maxWin: 250,
    nrOfSpins: 250,
    spinsValue: 10,
  },
  {
    segment: 'PVIP',
    minLoss: 1000,
    maxLoss: Infinity,
    rewardAmount: 50,
    wagering: 40,
    maxWin: 500,
    nrOfSpins: 250,
    spinsValue: 20,
  },
  {
    segment: 'VIP1',
    minLoss: 100,
    maxLoss: 349,
    rewardAmount: 5,
    wagering: 10,
    maxWin: 50,
    nrOfSpins: 25,
    spinsValue: 20,
  },
  {
    segment: 'VIP1',
    minLoss: 350,
    maxLoss: 749,
    rewardAmount: 20,
    wagering: 10,
    maxWin: 200,
    nrOfSpins: 100,
    spinsValue: 20,
  },
  {
    segment: 'VIP1',
    minLoss: 750,
    maxLoss: 999,
    rewardAmount: 40,
    wagering: 20,
    maxWin: 400,
    nrOfSpins: 200,
    spinsValue: 20,
  },
  {
    segment: 'VIP1',
    minLoss: 1000,
    maxLoss: 1999,
    rewardAmount: 50,
    wagering: 20,
    maxWin: 500,
    nrOfSpins: 100,
    spinsValue: 50,
  },
  {
    segment: 'VIP1',
    minLoss: 2000,
    maxLoss: Infinity,
    rewardAmount: 100,
    wagering: 20,
    maxWin: 1000,
    nrOfSpins: 200,
    spinsValue: 50,
  },
  {
    segment: 'VIP2',
    minLoss: 100,
    maxLoss: 349,
    rewardAmount: 5,
    wagering: 10,
    maxWin: 50,
    nrOfSpins: 10,
    spinsValue: 50,
  },
  {
    segment: 'VIP2',
    minLoss: 350,
    maxLoss: 749,
    rewardAmount: 20,
    wagering: 10,
    maxWin: 200,
    nrOfSpins: 40,
    spinsValue: 50,
  },
  {
    segment: 'VIP2',
    minLoss: 750,
    maxLoss: 999,
    rewardAmount: 40,
    wagering: 15,
    maxWin: 400,
    nrOfSpins: 80,
    spinsValue: 50,
  },
  {
    segment: 'VIP2',
    minLoss: 1000,
    maxLoss: 1999,
    rewardAmount: 50,
    wagering: 15,
    maxWin: 500,
    nrOfSpins: 50,
    spinsValue: 100,
  },
  {
    segment: 'VIP2',
    minLoss: 2000,
    maxLoss: 3999,
    rewardAmount: 100,
    wagering: 15,
    maxWin: 1000,
    nrOfSpins: 100,
    spinsValue: 100,
  },
  {
    segment: 'VIP2',
    minLoss: 4000,
    maxLoss: Infinity,
    rewardAmount: 200,
    wagering: 15,
    maxWin: 2000,
    nrOfSpins: 200,
    spinsValue: 100,
  },
  {
    segment: 'VIP3',
    minLoss: 100,
    maxLoss: 349,
    rewardAmount: 5,
    wagering: 10,
    maxWin: 50,
    nrOfSpins: 5,
    spinsValue: 100,
  },
  {
    segment: 'VIP3',
    minLoss: 350,
    maxLoss: 749,
    rewardAmount: 20,
    wagering: 10,
    maxWin: 200,
    nrOfSpins: 20,
    spinsValue: 100,
  },
  {
    segment: 'VIP3',
    minLoss: 750,
    maxLoss: 999,
    rewardAmount: 40,
    wagering: 10,
    maxWin: 400,
    nrOfSpins: 40,
    spinsValue: 100,
  },
  {
    segment: 'VIP3',
    minLoss: 1000,
    maxLoss: 1999,
    rewardAmount: 50,
    wagering: 10,
    maxWin: 500,
    nrOfSpins: 50,
    spinsValue: 100,
  },
  {
    segment: 'VIP3',
    minLoss: 2000,
    maxLoss: 3999,
    rewardAmount: 100,
    wagering: 10,
    maxWin: 1000,
    nrOfSpins: 50,
    spinsValue: 200,
  },
  {
    segment: 'VIP3',
    minLoss: 4000,
    maxLoss: Infinity,
    rewardAmount: 200,
    wagering: 10,
    maxWin: 2000,
    nrOfSpins: 100,
    spinsValue: 200,
  },
];

const defaultResult: CalculateRewardResult = {
  rewardAmount: null,
  wagering: null,
  maxWin: null,
  nrOfSpins: null,
  spinsValue: null,
};

export const calculateReward = (
  playerCurrentSegment: string,
  netLoss: number,
): CalculateRewardResult => {
  const result =
    rewardConditions.find(
      (condition) =>
        condition.segment === playerCurrentSegment &&
        netLoss >= condition.minLoss &&
        netLoss <= condition.maxLoss,
    ) || defaultResult;

  return {
    ...result,
    wagering: result.wagering !== null ? result.wagering : null,
  };
};

// If the last bonus date is within the last 6 weeks,
// the net loss is the NGR since the last manual reward
// If ngr is negative, the net loss is equal to ngr
export const calculateNetLoss = (
  shouldUseLastBonusDate: boolean,
  ngr: number,
  ngrSinceLastManualReward: number,
): number => {
  if (shouldUseLastBonusDate) {
    if (ngr < 0) {
      return ngr;
    }
    return ngrSinceLastManualReward;
  }
  return ngr;
};
