import {
  BooleanParam,
  DateTimeParam,
  StringParam,
  withDefault,
} from 'use-query-params';

import { SearchRewardsOrderBy } from '@/globalTypes';
import {
  RewardStatusParam,
  RewardTargetSystemParam,
  RewardTypeEnumParam,
  enumParam,
} from '@/utils/query/params';

const SearchRewardsOrderByParam = enumParam(SearchRewardsOrderBy);

export const queryParams = {
  createdAtFrom: DateTimeParam,
  createdAtTo: DateTimeParam,
  rewardId: StringParam,
  createdBy: StringParam,
  rewardName: StringParam,
  rewardTypes: RewardTypeEnumParam,
  rewardSystem: RewardTargetSystemParam,
  playerIds: StringParam,
  startsAtFrom: DateTimeParam,
  startsAtTo: DateTimeParam,
  endsAtFrom: DateTimeParam,
  endsAtTo: DateTimeParam,
  rewardStatus: RewardStatusParam,
  desc: withDefault(BooleanParam, true),
  orderBy: withDefault(
    SearchRewardsOrderByParam,
    SearchRewardsOrderBy.createdAt,
  ),
  before: StringParam,
  after: StringParam,
};
