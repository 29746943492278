import gql from 'graphql-tag';
import { useQuery } from 'urql';
import { useQueryParams } from 'use-query-params';

import { usePlayer } from '@/hooks';
import { mapVariables } from '@/utils';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  PlayerChallengeSearchQuery,
  PlayerChallengeSearchQueryVariables,
} from './__generated__/usePlayerChallenge';
import { queryParams } from './queryParams';

const PLAYER_CHALLENGE_QUERY = gql`
  query PlayerChallengeSearch(
    $playerIdList: [String!]
    $status: [PlayerProgressStatus!]!
    $after: String
    $before: String
    $desc: Boolean
    $first: Int
  ) {
    viewer {
      id
      playersChallenge: playersProgress(
        playerIdList: $playerIdList
        status: $status
        after: $after
        before: $before
        desc: $desc
        first: $first
      ) {
        edges {
          node {
            id
            challengeUUID
            status
            playerId
            challengeName
            startDate
            endDate
            challengeCriteriaTotal
            challengeCriteriaRemaining
            challengeCriteriaCompleted
            timesCompleted
            timesRestarted
            challengeRewards {
              challengeRewardType {
                name
                ... on FreeSpinChallengeReward {
                  freeSpinsAmount: amount
                }
                ... on MoneyDropChallengeReward {
                  moneyDropAmount: amount
                  currency
                }
                ... on BonusMoneyDropChallengeReward {
                  bonusMoneyDropAmount: amount
                  currency
                }
                ... on DynamicFreeSpinChallengeReward {
                  dynamicFreeSpinMultiplier: multiplier
                  currency
                }
                ... on DynamicMoneyDropChallengeReward {
                  dynamicMoneyDropMultiplier: multiplier
                  currency
                }
                ... on DynamicBonusMoneyDropChallengeReward {
                  dynamicBonusMoneyDropMultiplier: multiplier
                  currency
                }
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;

export default function usePlayerChallenge() {
  const [query, setQuery] = useQueryParams(queryParams);
  const { uuid } = usePlayer();

  const [{ data, fetching }, refresh] = useQuery<
    PlayerChallengeSearchQuery,
    PlayerChallengeSearchQueryVariables
  >({
    query: PLAYER_CHALLENGE_QUERY,
    variables: {
      playerIdList: [uuid],
      ...mapVariables(query),
      status: query.status || [],
    },
    pause: !uuid,
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makeOrderBy(query, setQuery),
    ...makePagination(query, setQuery, data?.viewer.playersChallenge.pageInfo),
    playerChallenge: data?.viewer.playersChallenge.edges?.map(
      (edge) => edge?.node,
    ),
  };
}
