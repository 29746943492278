import { Link } from 'gatsby';
import React, { FC } from 'react';

import { useGetPlayerOverviewPageLink } from '@/bits/links/useLink';
import {
  Card,
  CardCloseButton,
  CardOptions,
  ControlledModal,
  InlineIconButton,
  Table,
} from '@/components';
import { Nullable } from '@/types';
import { RenderSelfExclusionBrandValueType } from '@/utils';
import formatDate from '@/utils/formatter/formatDate';
import { EyeIcon } from './icons';

const getSelfExclusionConfiguredAt = (configuredAt: Nullable<string>) => {
  if (configuredAt) {
    return formatDate(configuredAt);
  }
  return '-';
};

const getSelfExclusionEndsAt = (
  configuredAt: Nullable<string>,
  endsAt: Nullable<string>,
) => {
  if (configuredAt && endsAt === null) {
    return 'Indefinite';
  }
  if (endsAt) {
    return formatDate(endsAt);
  }
  return '-';
};

export const GlobalPlayerSelfExclusionValues: FC<{
  title: string;
  values: Nullable<RenderSelfExclusionBrandValueType[]>;
}> = ({ title, values }) => {
  const getPlayerOverviewLink = useGetPlayerOverviewPageLink();

  return (
    <ControlledModal
      content={
        <Card
          title={title}
          size="lg"
          options={
            <CardOptions>
              <CardCloseButton />
            </CardOptions>
          }
        >
          <div className="p-3">
            {values && (
              <Table
                headings={[
                  'Brand',
                  'Configured At',
                  'Ends At',
                  'Status',
                  'Player',
                ]}
                rows={values.map((row, i) => {
                  return {
                    key: `brand-values-${i}`,
                    data: [
                      <span>{row.brand}</span>,
                      <span>
                        {getSelfExclusionConfiguredAt(row.configuredAt)}
                      </span>,
                      <span>
                        {getSelfExclusionEndsAt(
                          row.configuredAt,
                          row.exclusionEndsAt,
                        )}
                      </span>,
                      <span>{row.status}</span>,
                      <Link to={getPlayerOverviewLink(row.playerId) ?? ''}>
                        {row.rawPlayerId}
                      </Link>,
                    ],
                  };
                })}
              />
            )}
          </div>
        </Card>
      }
    >
      <InlineIconButton>
        <EyeIcon className="text-red-600" />
      </InlineIconButton>
    </ControlledModal>
  );
};
