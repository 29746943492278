import React, { FC, ReactNode } from 'react';

import { Card, CardCloseButton, CardOptions } from './Card';
import { ErrorIcon, InformationIcon } from './icons';
import { InlineIconButton } from './InlineIconButton';
import { InlineSpinner } from './InlineSpinner';
import { ControlledModal } from './Modal';
import { Table } from './Table';

type Columns = {
  label: string;
  value: string;
};

type ValueContainerProps = {
  title: string | ReactNode;
  children: ReactNode;
};

const ValueContainer: FC<ValueContainerProps> = ({ title, children }) => (
  <div className="text-black dark:text-gray-200">
    <label className="text-sm text-gray-500 dark:text-gray-400 font-semibold">
      {title}
    </label>
    {children}
  </div>
);

const MultipleValues: FC<{
  title: string | ReactNode;
  rows: Columns[] | undefined;
  numberOfValues?: number;
  fetching?: boolean;
  error?: boolean;
  className?: string;
}> = ({ title, rows, numberOfValues = 4, fetching, error, className }) => {
  if (fetching) {
    return <InlineSpinner />;
  }

  if (error) {
    return <ErrorIcon className="text-gray-600" />;
  }

  if (!rows?.length) {
    return (
      <ValueContainer title={title}>
        <div className="text-gray-500">-</div>
      </ValueContainer>
    );
  }

  // If the number of rows is less than numberOfValues, we display all the rows in a single line.
  if (rows.length < numberOfValues) {
    return (
      <ValueContainer title={title}>
        <div>{rows.map((row) => row.value).join(', ')}</div>
      </ValueContainer>
    );
  }

  return (
    <ValueContainer title={title}>
      <div className={className}>
        <span>{rows.length}</span>
        <ControlledModal
          content={
            <Card
              title={title}
              size="lg"
              options={
                <CardOptions>
                  <CardCloseButton />
                </CardOptions>
              }
            >
              <div className="p-3">
                <Table
                  headings={[rows[0].label]}
                  rows={rows.map((row, index) => ({
                    key: `row-${index}`,
                    data: [row.value],
                  }))}
                />
              </div>
            </Card>
          }
        >
          <InlineIconButton>
            <InformationIcon />
          </InlineIconButton>
        </ControlledModal>
      </div>
    </ValueContainer>
  );
};

export default MultipleValues;
