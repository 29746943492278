import { format, isValid, parseISO } from 'date-fns';

import { Nullable } from '@/types';

export const isValidDate = (
  date?: Nullable<ConstructorParameters<typeof Date>[number]>,
) => {
  if (typeof date === 'string') {
    return isValid(parseISO(date));
  }

  return date ? isValid(new Date(date)) : false;
};

export const dateFormats = {
  date: 'yyyy-MM-dd',
  dateTime: 'yyyy-MM-dd HH:mm',
};

export default function formatDate(
  date?: Nullable<ConstructorParameters<typeof Date>[number]>,
  hideHour?: boolean,
) {
  if (!date || !isValidDate(date)) {
    return '-';
  }

  const dateObj = typeof date === 'string' ? parseISO(date) : new Date(date);

  if (!isValid(dateObj)) {
    return '-';
  }

  return format(dateObj, hideHour ? dateFormats.date : dateFormats.dateTime);
}
