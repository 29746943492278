import {
  BooleanParam,
  DateTimeParam,
  StringParam,
  withDefault,
} from 'use-query-params';

import { PlayerRewardStatusParam } from '@/utils/query/params';

export const queryParams = {
  playerId: StringParam,
  desc: withDefault(BooleanParam, true),
  rewardStatus: PlayerRewardStatusParam,
  assignationId: StringParam,
  assignedAtFrom: DateTimeParam,
  assignedAtTo: DateTimeParam,
  before: StringParam,
  after: StringParam,
};
