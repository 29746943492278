import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { TransformComponent } from 'react-zoom-pan-pinch';

import { logger } from '@/utils';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { FileLayout } from '../FileLayout';
import { FileDriver } from '.';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';

const EmptyFragmentRenderer = () => (
  <div className="dark:text-white">Loading PDF...</div>
);

const PDFDriver: FileDriver = ({ onLoaded, isLoading, filePath }) => {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const onLoadSuccess = ({ numPages }: { numPages: number }): void => {
    onLoaded();
    setNumPages(numPages);
  };

  const handleNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handlePrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  return (
    <FileLayout
      isLoading={isLoading}
      filePath={filePath}
      pagination={{
        currentPage: pageNumber,
        totalPages: numPages,
        onNextPage: () => handleNextPage(),
        onPrevPage: () => handlePrevPage(),
      }}
    >
      <TransformComponent>
        <Document
          file={filePath}
          onLoadSuccess={(props) => onLoadSuccess(props)}
          onLoadError={(e) => logger.warn(e)}
          loading={EmptyFragmentRenderer}
        >
          <Page key={`page_${pageNumber}`} pageNumber={pageNumber} />
        </Document>
      </TransformComponent>
    </FileLayout>
  );
};

export default PDFDriver;
