import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { feedback } from 'react-feedbacker';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco, nord } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { useMutation } from 'urql';

import {
  ErrorMessage,
  Form,
  SelectField,
  SubmitButton,
  useModalContext,
} from '@/components';
import { useTheme, useTranslate } from '@/contexts';
import { ManualDecisionType, Maybe } from '@/globalTypes';
import { Nullable } from '@/types';
import { assert } from '@/utils/error';
import {
  ResolveRewardMutation,
  ResolveRewardMutationVariables,
} from './__generated__/RewardResolveForm';

const query = graphql`
  query SanityRewardResolveForm {
    sanityRewardResolveForm {
      title {
        ...SanityLocaleString
      }
      manualCredited {
        ...SanityLocaleString
      }
      removeReward {
        ...SanityLocaleString
      }
      rewardResolved {
        ...SanityLocaleString
      }
    }
  }
`;

const resolveRewardMutation = gql`
  mutation ResolveReward(
    $rewardId: String!
    $playerId: ID!
    $assignationId: String
    $manualDecision: ManualDecisionType!
  ) {
    resolveReward(
      id: $rewardId
      playerId: $playerId
      assignationId: $assignationId
      manualDecision: $manualDecision
    )
  }
`;

type Props = {
  playerId: string;
  reason: Nullable<string>;
  rewardId: string;
  assignationId: Maybe<string>;
};

const RewardResolveForm = ({
  playerId,
  reason,
  rewardId,
  assignationId,
}: Props) => {
  const staticData =
    useStaticQuery<Queries.SanityRewardResolveFormQuery>(query);

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const form = staticData.sanityRewardResolveForm;
  assert(form, 'missing form data');
  const { t } = useTranslate();

  const [resolveRewardState, resolveReward] = useMutation<
    ResolveRewardMutation,
    ResolveRewardMutationVariables
  >(resolveRewardMutation);

  const theme = useTheme();
  const syntaxStyle = theme.name === 'dark' ? nord : docco;

  const defaultValues: { resolveReward: '' | ManualDecisionType } = {
    resolveReward: '',
  };

  const { close } = useModalContext();

  const onSubmit = (values: typeof defaultValues) => {
    if (values.resolveReward) {
      resolveReward({
        rewardId,
        playerId,
        assignationId,
        manualDecision: values.resolveReward,
      }).then((res) => {
        if (res.error?.message) {
          setErrorMessage(res.error.message);
        } else if (close) {
          feedback.success(t(form.rewardResolved));
          close();
        }
      });
    }
  };

  return (
    <Form defaultValues={defaultValues} onSubmit={onSubmit} className="grid">
      <div className="p-3">
        <SelectField
          className="mb-3"
          title={t(form.title)}
          name="resolveReward"
          id="RewardResolveForm__resolveReward"
          required
          options={[
            {
              label: t(form.manualCredited),
              value: ManualDecisionType.manualCredited,
            },
            {
              label: t(form.removeReward),
              value: ManualDecisionType.removeReward,
            },
          ]}
        />
        {reason && (
          <SyntaxHighlighter
            language="json"
            style={syntaxStyle}
            className="col-span-full text-sm"
          >
            {JSON.stringify(JSON.parse(reason), null, 2)}
          </SyntaxHighlighter>
        )}

        <ErrorMessage message={errorMessage} />
        <div className="flex justify-end mt-3">
          <SubmitButton value="Submit" disabled={resolveRewardState.fetching} />
        </div>
      </div>
    </Form>
  );
};

export default RewardResolveForm;
