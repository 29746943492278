import React from 'react';

import { Card, CardCloseButton, CardOptions, DetailList } from '@/components';
import formatMoney from '@/utils/formatter/formatMoney';
import { GlobalPlayerComplianceGlobalLimitsQuery } from './__generated__/components';

const LimitsDetails = ({
  limitName,
  activeFrom,
  euroThreshold,
  euroAmount,
  limitReached,
  latestChangeAt,
  rollingPeriod,
  title,
}: GlobalPlayerComplianceGlobalLimitsQuery['playerGlobal']['complianceGlobalPlayerLimits'][number] & {
  title: string;
}) => {
  const items = [
    {
      label: 'Limit Name',
      value: limitName,
    },
    {
      label: 'Active From',
      value: activeFrom || '-',
    },
    {
      label: 'Euro Threshold',
      value: formatMoney(euroThreshold, 'EUR'),
    },
    {
      label: 'Euro Amount',
      value: formatMoney(euroAmount, 'EUR'),
    },
    {
      label: 'Limit Reached',
      value: limitReached ? 'Yes' : 'No',
    },
    {
      label: 'Latest Change At',
      value: latestChangeAt,
    },
    {
      label: 'Rolling Period',
      value:
        rollingPeriod?.periodType === 'Finite'
          ? rollingPeriod.period
          : rollingPeriod.periodType,
    },
  ];

  return (
    <Card
      size="lg"
      title={title}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <DetailList items={items} />
    </Card>
  );
};

export default LimitsDetails;
