import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { MdWarning } from 'react-icons/md';
import { useMutation, useQuery } from 'urql';

import {
  Card,
  CardCloseButton,
  CardOptions,
  ErrorMessage,
  Form,
  SubmitButton,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import {
  PlayerIsTestAccountFormQuery,
  PlayerIsTestAccountFormQueryVariables,
  SetAccountAsTestMutation,
} from './__generated__/PlayerSetTestAccountForm';

const query = graphql`
  query SanityPlayerSetTestAccountForm {
    sanityPlayerSetTestAccountForm {
      title {
        ...SanityLocaleString
      }
    }
  }
`;

const playerQuery = gql`
  query PlayerIsTestAccountForm($playerId: ID!) {
    player(playerId: $playerId) {
      id
      isTestAccount
    }
  }
`;

const setAccountAsTestMutation = gql`
  mutation SetAccountAsTest($playerId: ID!) {
    setAccountAsTest(playerId: $playerId) {
      id
      isTestAccount
    }
  }
`;

const PlayerSetTestAccountForm: FC<{ playerId: string }> = ({ playerId }) => {
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();
  const { close } = useModalContext();
  const { t } = useTranslate();

  const staticData =
    useStaticQuery<Queries.SanityPlayerSetTestAccountFormQuery>(query);

  const form = staticData.sanityPlayerSetTestAccountForm;

  const [{ data }] = useQuery<
    PlayerIsTestAccountFormQuery,
    PlayerIsTestAccountFormQueryVariables
  >({
    query: playerQuery,
    variables: { playerId },
  });

  const [setAccountAsTestState, setAccountAsTest] =
    useMutation<SetAccountAsTestMutation>(setAccountAsTestMutation);

  const onSubmit = () => {
    setAccountAsTest({
      playerId: data?.player.id,
    }).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        close();
      }
    });
  };

  if (!form) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="flex flex-col gap-6 p-3">
        <Form
          defaultValues={{}}
          onSubmit={onSubmit}
          className="grid grid-cols-2 sm:grid-cols-3 gap-6"
          description={
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-1">
                <MdWarning className="text-orange-300" />
                WARNING!
              </div>
              <ul className="list-decimal list-inside ml-4">
                <li>This is a irreversible action.</li>
                <li>
                  This can be applied only to existing accounts that does not
                  have any transactions.
                </li>
              </ul>
              <div>
                Are you sure you want to set this account as a test account?
              </div>
            </div>
          }
        >
          <ErrorMessage message={errorMessage} />
          <SubmitButton
            value="Submit"
            disabled={setAccountAsTestState.fetching}
          />
        </Form>
      </div>
    </Card>
  );
};

export default PlayerSetTestAccountForm;
