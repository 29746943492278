import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useCallback } from 'react';
import { useQuery } from 'urql';

import { limitPeriodDetailList_periodLimit } from '@/bits/limit-period-detail-list/component';
import { Card, CardBody, CardOptions, CardOptionsButton } from '@/components';
import { RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import { limitFragment } from '@/fragments/Limits';
import { Nullable } from '@/types';
import formatMoney from '@/utils/formatter/formatMoney';
import {
  PlayerWithdrawalLimitsBoxQuery,
  PlayerWithdrawalLimitsBoxQueryVariables,
} from './__generated__/component';
import WithdrawalLimitBars from './WithdrawalLimitBars';

export const Fragment = graphql`
  fragment SanityPlayerWithdrawalLimitsBlock on SanityPlayerWithdrawalLimitsBlock {
    title {
      ...SanityLocaleString
    }
  }
`;

export const PLAYER_WITHDRAWAL_LIMITS_QUERY = gql`
  query PlayerWithdrawalLimitsBox($playerId: ID!) {
    player(playerId: $playerId) {
      id
      wallet {
        id
        currency
        withdrawalLimit {
          dayLimit {
            ...PeriodLimit
            ...LimitPeriodDetailList_periodLimit
          }
          weekLimit {
            ...PeriodLimit
            ...LimitPeriodDetailList_periodLimit
          }
          monthLimit {
            ...PeriodLimit
            ...LimitPeriodDetailList_periodLimit
          }
        }
      }
    }
  }
  ${limitFragment}
  ${limitPeriodDetailList_periodLimit}
`;

const PlayerWithdrawalLimitsBlock: FC<{
  block: Queries.SanityPlayerWithdrawalLimitsBlock;
}> = ({ block }) => {
  const { t } = useTranslate();
  const { playerId } = useParams();

  const [{ data, fetching }, refresh] = useQuery<
    PlayerWithdrawalLimitsBoxQuery,
    PlayerWithdrawalLimitsBoxQueryVariables
  >({
    query: PLAYER_WITHDRAWAL_LIMITS_QUERY,
    variables: {
      playerId,
    },
  });

  const wallet = data?.player.wallet;
  const withdrawLimit = wallet?.withdrawalLimit;

  const currencyValueFormatter = useCallback(
    (value: Nullable<number>) => formatMoney(value, wallet?.currency),
    [wallet?.currency],
  );

  const dailyLimitValue = `Daily ${formatMoney(
    (withdrawLimit?.dayLimit?.value ?? 0) -
      (withdrawLimit?.dayLimit?.available ?? 0),
  )} / ${formatMoney(withdrawLimit?.dayLimit?.value, wallet?.currency)}`;

  const monthlyLimitValue = `Monthly ${formatMoney(
    (withdrawLimit?.monthLimit?.value ?? 0) -
      (withdrawLimit?.monthLimit?.available ?? 0),
  )} / ${formatMoney(withdrawLimit?.monthLimit?.value, wallet?.currency)}`;

  return (
    <Card
      size="lg"
      title={t(block.title)}
      options={
        <CardOptions>
          <CardOptionsButton
            className="flex"
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
          <div className="col-span-full">
            <label className="text-sm text-gray-500 dark:text-gray-300 font-semibold">
              Withdraw Limits
            </label>
            <WithdrawalLimitBars
              wallet={wallet}
              fetching={fetching}
              playerId={playerId}
              dailyLimitValue={dailyLimitValue}
              monthlyLimitValue={monthlyLimitValue}
              currencyValueFormatter={currencyValueFormatter}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerWithdrawalLimitsBlock;
