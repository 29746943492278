import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { useQuery } from 'urql';

import { BooleanValue } from '@/bits';
import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  ControlledModal,
  InlineIconButton,
} from '@/components';
import { EditIcon, InformationIcon, RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import { PlayerGlobalUpdateGamanzaStatusForm } from '@/forms';
import {
  GlobalPlayerGamanzaOverviewBoxQuery,
  GlobalPlayerGamanzaOverviewBoxQueryVariables,
} from './__generated__/component';
import GamanzaGlobalPlayerStatusDetails from './GamanzaGlobalPlayerStatusDetails';

export const Fragment = graphql`
  fragment SanityGlobalPlayerGamanzaOverviewBlock on SanityGlobalPlayerGamanzaOverviewBlock {
    title {
      ...SanityLocaleString
    }
  }
`;

const QUERY = gql`
  query GlobalPlayerGamanzaOverviewBox($playerGlobalId: ID!) {
    playerGlobal(playerGlobalId: $playerGlobalId) {
      id
      getGamanzaGlobalPlayerBlockStatus {
        isBlocked
        updatedAt
        updatedBy {
          ... on AgentInitiator {
            agent {
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;

const GlobalPlayerGamanzaOverviewBlock: FC<{
  block: Queries.SanityPlayerRgBlocksOverviewBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const { playerGlobalId } = useParams();

  const [{ data, fetching }, refresh] = useQuery<
    GlobalPlayerGamanzaOverviewBoxQuery,
    GlobalPlayerGamanzaOverviewBoxQueryVariables
  >({
    query: QUERY,
    variables: {
      playerGlobalId,
    },
  });

  const gamanzaGlobalPlayerBlockStatus =
    data?.playerGlobal.getGamanzaGlobalPlayerBlockStatus;

  return (
    <Card
      size="lg"
      title={t(block.title)}
      options={
        <CardOptions>
          <CardOptionsButton
            className="flex"
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
          <BooleanValue
            fetching={fetching}
            title="Gamanza Blocked"
            value={gamanzaGlobalPlayerBlockStatus?.isBlocked}
            suffix={
              playerGlobalId && (
                <>
                  <ControlledModal
                    content={
                      <PlayerGlobalUpdateGamanzaStatusForm
                        playerGlobalId={playerGlobalId}
                        blockPlayer={
                          gamanzaGlobalPlayerBlockStatus?.isBlocked ?? false
                        }
                      />
                    }
                  >
                    <InlineIconButton tippyContent="Edit status">
                      <EditIcon />
                    </InlineIconButton>
                  </ControlledModal>
                  <ControlledModal
                    content={
                      gamanzaGlobalPlayerBlockStatus ? (
                        <GamanzaGlobalPlayerStatusDetails
                          {...gamanzaGlobalPlayerBlockStatus}
                        />
                      ) : null
                    }
                  >
                    <InlineIconButton tippyContent="Status Details">
                      <InformationIcon />
                    </InlineIconButton>
                  </ControlledModal>
                </>
              )
            }
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default GlobalPlayerGamanzaOverviewBlock;
