import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { LocationProvider } from '@reach/router';
import { WrapRootElementBrowserArgs } from 'gatsby';
import React, { FC, ReactNode } from 'react';
import Modal from 'react-modal';
import { Client, Provider } from 'urql';
import { QueryParamProvider } from 'use-query-params';
import { ReachAdapter } from 'use-query-params/adapters/reach';

import { makeAuthClient } from '@/bootstrap/okta';
import { makeClient } from '@/bootstrap/urql';
import { Feedbacker } from '@/components';
import {
  DataSourceProvider,
  ElasticIndexPrefixProvider,
  TimeProvider,
  TranslateProvider,
} from '@/contexts';
import { NewNodesProvider } from '@/contexts/NewNodesContext';
import { OrgEnum } from '@/globalTypes';
import '@/styles/global.css';
import { getOperatorFromEnv, operatorsById } from '@/utils/operators';
import { useDevelopmentGraphQLEndpoint } from '@/utils/test-utils';

Modal.setAppElement('#___gatsby');

const clients: Partial<Record<OrgEnum, Client>> = {};

const ClientProvider: FC<{
  authService: OktaAuth;
  children: ReactNode;
}> = ({ children, authService }) => {
  const currentOperator = getOperatorFromEnv();
  const developmentEndpoint = useDevelopmentGraphQLEndpoint();

  const operator = currentOperator || operatorsById[OrgEnum.IN];

  const client =
    clients[operator.id] ||
    (clients[operator.id] = makeClient(
      developmentEndpoint ?? operator.graphQLEndpoint,
      authService,
    ));

  return <Provider value={client}>{children}</Provider>;
};

const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
  window.location.replace(
    toRelativeUrl(originalUri || '/', window.location.origin),
  );
};

export const wrapRootElement = ({ element }: WrapRootElementBrowserArgs) => {
  const authService = makeAuthClient();

  return (
    <LocationProvider>
      <QueryParamProvider adapter={ReachAdapter}>
        <Security
          oktaAuth={authService}
          restoreOriginalUri={restoreOriginalUri}
        >
          <ClientProvider authService={authService}>
            <TranslateProvider>
              <TimeProvider root>
                <ElasticIndexPrefixProvider>
                  <DataSourceProvider>
                    <NewNodesProvider>
                      <Feedbacker />
                      {element}
                    </NewNodesProvider>
                  </DataSourceProvider>
                </ElasticIndexPrefixProvider>
              </TimeProvider>
            </TranslateProvider>
          </ClientProvider>
        </Security>
      </QueryParamProvider>
    </LocationProvider>
  );
};
