import { useOktaAuth } from '@okta/okta-react';
import { jwtDecode } from 'jwt-decode';
import { useMemo } from 'react';
import { useAsync } from 'react-use';

export const useOktaInfo = () => {
  const auth = useOktaAuth();

  const idToken = useAsync(async () => {
    return auth.oktaAuth.getIdToken();
  }, [auth.oktaAuth]);

  const decoded = useMemo(
    () => idToken.value && jwtDecode(idToken.value),
    [idToken.value],
  );

  return (decoded ? decoded : {}) as { name?: string; email?: string };
};
