import React, { FC, ReactNode } from 'react';

import { Copy, InlineSpinner } from '@/components';
import { Nullable } from '@/types';
import { ErrorIcon } from './icons/ErrorIcon';

export const renderOptionalValue = <Value extends string | number | ReactNode>(
  value: Nullable<Value>,
) => value || '-';

export const renderCopyButton = (
  value: Nullable<string | number>,
  children: ReactNode,
  hasCopy: boolean = true,
): ReactNode => {
  return hasCopy ? <Copy value={value}>{children}</Copy> : children || value;
};

const renderContent = (
  value: Nullable<string | number> | undefined,
  children: ReactNode,
  prefix: ReactNode,
  suffix: ReactNode,
  hasCopy: boolean | undefined,
): ReactNode => {
  const nonValueContent = (
    <>
      {renderOptionalValue(children)}
      {suffix}
    </>
  );

  if (value) {
    return (
      <div className="flex items-center">
        {prefix}
        {renderCopyButton(value, children, hasCopy)}
        {suffix}
      </div>
    );
  }

  if (children) {
    return nonValueContent;
  }

  return <span className="text-gray-500">{nonValueContent}</span>;
};

export const Value: FC<{
  title: string | ReactNode;
  value?: Nullable<string | number>;
  fetching?: boolean;
  error?: boolean;
  prefix?: ReactNode;
  suffix?: ReactNode;
  className?: string;
  hasCopy?: boolean;
  children?: ReactNode;
}> = ({
  title,
  value,
  fetching,
  error,
  children,
  prefix,
  suffix,
  hasCopy,
  className,
}) => {
  if (fetching) {
    return <InlineSpinner />;
  }

  if (error) {
    return <ErrorIcon className="text-gray-600" />;
  }

  return (
    <div className="text-black dark:text-gray-200">
      <label className="text-sm text-gray-500 dark:text-gray-400 font-semibold">
        {title}
      </label>
      <div className={className}>
        {renderContent(value, children, prefix, suffix, hasCopy)}
      </div>
    </div>
  );
};
