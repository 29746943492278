import { graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';
import { gql } from 'urql';

import { Card, CardCloseButton, CardOptions, DetailList } from '@/components';
import { useTranslate } from '@/contexts';
import { LimitTypeEnum, Maybe } from '@/globalTypes';
import { Nullable } from '@/types';
import formatDate from '@/utils/formatter/formatDate';
import { LimitPeriodDetailList_periodLimitFragment } from './__generated__/component';

const query = graphql`
  query SanityLimitPeriodDetailBit {
    sanityLimitPeriodDetailListBit {
      title {
        ...SanityLocaleString
      }
      periodLabel {
        ...SanityLocaleString
      }
      valueLabel {
        ...SanityLocaleString
      }
      availableLabel {
        ...SanityLocaleString
      }
      configuredAtLabel {
        ...SanityLocaleString
      }
      currentPeriodEndLabel {
        ...SanityLocaleString
      }
      pendingLimitFromLabel {
        ...SanityLocaleString
      }
      pendingLimitValueLabel {
        ...SanityLocaleString
      }
      isDefaultLabel {
        ...SanityLocaleString
      }
    }
  }
`;

export const limitPeriodDetailList_periodLimit = gql`
  fragment LimitPeriodDetailList_periodLimit on PeriodLimit {
    value
    available
    currentPeriodEndTime
    limitPeriodType
    configuredAt
    pendingPeriodLimit {
      configuredAt
      validFrom
      value
    }
    isDefault
  }
`;

export const LimitPeriodDetailList: FC<{
  limitTypeTitle: string;
  limit: Maybe<LimitPeriodDetailList_periodLimitFragment | undefined>;
  limitPeriodType: LimitTypeEnum;
  formatValue: (value: Nullable<number>) => string;
}> = ({ limit, limitTypeTitle, limitPeriodType, formatValue }) => {
  const { t } = useTranslate();

  const bit =
    useStaticQuery<Queries.SanityLimitPeriodDetailBitQuery>(
      query,
    ).sanityLimitPeriodDetailListBit;

  if (!bit) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={`${t(bit.title)} - ${limitTypeTitle}`}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <DetailList
        items={[
          {
            label: t(bit.periodLabel),
            value: limitPeriodType,
          },
          {
            label: t(bit.valueLabel),
            value: formatValue(limit?.value),
          },
          {
            label: t(bit.availableLabel),
            value: formatValue(limit?.available),
          },
          {
            label: t(bit.configuredAtLabel),
            value: formatDate(limit?.configuredAt),
          },
          {
            label: t(bit.currentPeriodEndLabel),
            value: formatDate(limit?.currentPeriodEndTime),
          },
          {
            label: t(bit.pendingLimitFromLabel),
            value: formatDate(limit?.pendingPeriodLimit?.validFrom),
          },
          {
            label: t(bit.pendingLimitValueLabel),
            value: formatValue(limit?.pendingPeriodLimit?.value),
          },
          {
            label: t(bit.isDefaultLabel),
            value: limit?.isDefault ? 'Yes' : 'No',
          },
        ]}
      />
    </Card>
  );
};
