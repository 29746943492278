import { formatISO } from 'date-fns';
import gql from 'graphql-tag';
import { useMemo } from 'react';
import { useQuery } from 'urql';

import { RangeDate } from '@/globalTypes';
import { useQueryParamsWithTime } from '@/hooks';
import { mapVariables } from '@/utils';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  PlayerInformationHistoryQuery,
  PlayerInformationHistoryQueryVariables,
} from './__generated__/usePlayerInformationHistory';
import { queryParams } from './queryParams';

const QUERY = gql`
  query PlayerInformationHistory(
    $playerGlobalId: ID
    $playerIds: [ID!]!
    $playerGlobalUUID: String
    $playerUUIDs: [String!]!
    $eventType: PlayerInformationHistoryEventType
    $timestamp: RangeDate
    $orderBy: PlayerInformationHistoryOrderBy
    $desc: Boolean
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    viewer {
      id
      playerInformationHistory(
        playerGlobalId: $playerGlobalId
        playerIds: $playerIds
        playerGlobalUUID: $playerGlobalUUID
        playerUUIDs: $playerUUIDs
        eventType: $eventType
        timestamp: $timestamp
        desc: $desc
        orderBy: $orderBy
        before: $before
        after: $after
        first: $first
        last: $last
      ) {
        edges {
          node {
            id
            eventId
            playerGlobalId
            playerGlobalUUID
            playerId
            playerUUID
            eventType
            service
            timestamp
            value
            initiator {
              ... on PlayerInitiator {
                player {
                  firstName
                  id
                  lastName
                }
              }
              ... on AgentInitiator {
                agent {
                  firstName
                  lastName
                }
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;

export default function usePlayerInformationHistory(playerId: string) {
  const [query, setQuery] = useQueryParamsWithTime(queryParams, {
    fromAndToOptional: true,
  });

  const timestamp = useMemo((): RangeDate | undefined => {
    if (query.from && query.to) {
      return {
        from: formatISO(query.from),
        to: formatISO(query.to),
      };
    }
    return undefined;
  }, [query.from, query.to]);

  const playerUUIDs = useMemo(() => {
    if (query.playerUUID) {
      return [query.playerUUID];
    }
    return [];
  }, [query.playerUUID]);

  const variables = {
    playerIds: [playerId],
    ...mapVariables(query),
    playerUUIDs,
    timestamp,
  };

  const [{ data, fetching }, refresh] = useQuery<
    PlayerInformationHistoryQuery,
    PlayerInformationHistoryQueryVariables
  >({
    query: QUERY,
    variables,
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makeOrderBy(query, setQuery),
    ...makePagination(
      query,
      setQuery,
      data?.viewer.playerInformationHistory.pageInfo,
    ),
    entries: data?.viewer.playerInformationHistory.edges?.map(
      (edge) => edge?.node,
    ),
  };
}
